import ShopApiClient from './ShopApiClient';

class CatelogueItemAPI extends ShopApiClient {
  constructor() {
    super('catalog_items', { accountScoped: true });
  } 

  getCatelogueCategories(){
    return axios.get(`${this.url}/categories`);
  }
  getCatelogueItemsByCategoryId(categoryId){
    return axios.get(`${this.url}/items?categories=${categoryId}`);
  }
  getCatelogueItemByCategoryId(catelogueId){
    return axios.get(`${this.url}/?id=${catelogueId}`);
  }
}

export default new CatelogueItemAPI();
