<template>
  <div :class="['loader', size]"></div>
</template>

<script>
export default {
  name: 'LoaderRound',
  props: {
    size: {
      type: String,
      default: 'medium',
      validator: function (value) {
        return ['small', 'medium', 'large'].includes(value);
      }
    }
  }
};
</script>

<style scoped>
.loader {
  border: 4px solid var(--primary-color);
  border-radius: 50%;
  border-top: 4px solid transparent;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

.small {
  width: 20px;
  height: 20px;
  border-width: 2px;
}

.medium {
  width: 40px;
  height: 40px;
  border-width: 4px;
}

.large {
  width: 60px;
  height: 60px;
  border-width: 6px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
