<template>
  <div class="section_gap_x section_gap_y">
    <div class="img_wraper">
      <img :src="catelogue.image_link" alt="">
    </div>

    <div>
      <div class="title_header section_gap_y">
        <p class="shop_label sp_title_lg">{{catelogue.title}}</p>
        <p class="sp_title_md description">{{catelogue.description}}</p>
      </div>
    </div>
    <div v-if="catelogue.variants">
      <div class="variant_table_container">
        <table class="variants">
          <thead>
            <tr class="row header">
              <th>{{$t("CATELOGUE_ITEM.VARIANT.TABLE_HEADER.TITLE")}}</th>
              <th>{{$t("CATELOGUE_ITEM.VARIANT.TABLE_HEADER.PRICE")}}</th>
              <th>{{$t("CATELOGUE_ITEM.VARIANT.TABLE_HEADER.QUANTITY")}}</th>
              <th>{{$t("CATELOGUE_ITEM.VARIANT.TABLE_HEADER.TOTAL_PRICE")}}</th>
            </tr>
          </thead>
          <tbody v-if="!isCatalogLoading">
            <tr v-for="(variant, index) in catelogue.variants" :key="index" class="row">
              <td> <span v-if="index !== 0">{{catelogue.title}} - </span>{{ variant.title }}</td>
              <td class="align_right">{{ variant.price }}</td>
              <td>
                <div class="btns">
                  <span class="icon" @click="decreaseQuantity(index)"><fluent-icon icon="subtract" /></span>
                  {{ variant.orderQuantity }}
                  <span class="icon" @click="increaseQuantity(index)"><fluent-icon icon="add" /></span>
                </div>
              </td>
              <td class="align_right">{{ (variant.price * variant.orderQuantity).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        <loader-round v-if="isCatalogLoading"  />
        <p v-if="!isCatalogLoading && !catelogue.variants.length" class="no_item">No product available</p>
      </div>
      <div>
        <div class="action_btns section_gap_y">
          <shop-button
            :disabled="isOrderButtonDisabled || isAddToCartDisabled || isNotInBusinessHours"
            @click="addToCartHandler">
            {{$t("CATELOGUE_ITEM.VARIANT.BTNS.ADD_TO_CART")}}
          </shop-button>
          <shop-button
            @click="orderNowhandler"
            :disabled="isOrderButtonDisabled || isAddToCartDisabled || isNotInBusinessHours"
          >
            <!-- {{$t("CATELOGUE_ITEM.VARIANT.BTNS.ORDER")}} -->
            Continue
          </shop-button>
        </div>
        <p v-if="isNotInBusinessHours" style="text-align:center;">{{$t("CATELOGUE_ITEM.VARIANT.BTNS.BUSINESS_HOUR_MSG")}}</p>
      </div>

    </div>

  </div>
</template>

<script>
import ShopButton from '../../../components/ShopButton.vue';
import CatelogueItemAPI from "../../../api/catelogueItemAPI";
import {StorageHelpers,SHOP_CONSTANT} from "./merchantHelper";
import alertMixin from 'shared/mixins/alertMixin';
import placeholderImage from "../../../../dashboard/assets/images/external/img_placeholder.jpg"
import LoaderRound from '../components/LoaderRound.vue';


export default {
  components: {
    ShopButton,
    LoaderRound,
  },
  mixins:[alertMixin],
  data() {
    // const merchant = StorageHelpers.getMerchantById(this.$route.params.accountId);
    return {
      // merchant,
      category_id: null,
      catelogue: {variants:[]},
      isAddToCartDisabled: false,
      isCatalogLoading: false,
    };
  },
  computed:{
    merchant(){
      return this.$store.getters["merchants/getCurrentMerchant"](this.$route.params.accountId);
    },
    currentCart(){
      return this.$store.getters["carts/getCurrentCart"](this.$route.params.accountId);
    },
    isOrderButtonDisabled(){
      return this.catelogue.variants?.every(variant=>variant.orderQuantity === 0)
    },
    isNotInBusinessHours() {
      return this.isOutsideBusinessHours();
    },
  },
  mounted() {
    this.$store.dispatch("carts/get",{ })
    this.fetchProductById();
    this.checkCurrentOrderStatus();
  },
  methods: {
    async fetchProductById() {
      const category_id = this.$route.query.product_id;
      if (!category_id) {
        return
      }
      try {
        this.isCatalogLoading = true;
        const {data} = await CatelogueItemAPI.getCatelogueItemByCategoryId(category_id);
        // filter variant.price > 0
        this.catelogue = data || {variants:[]}

        // add the main catalogue as a variant
        const mainItemPrice = parseFloat(data.price?.split(" ")[0] || 0);
        if (mainItemPrice > 0) {
          this.catelogue.variants.unshift({
            id: data.id,
            title: data.title || "",
            price: mainItemPrice,
            available_inventory: data.available_inventory,
          })
        }

        this.catelogue.image_link = await this.getValidImageUrl(this.catelogue.image_link);
        // const existingCart = StorageHelpers.getCartById(this.$route.params.accountId);

        const newVariants = this.catelogue.variants.map(variant=>{
          // const existingItem = existingCart.order_items.find(item => item.varient_id === variant.id);
          const existingItem = this.currentCart.order_items.find(item => item.varient_id === variant.id);
          console.log("From store",{existingItem});
          const orderQuantity = existingItem ? existingItem.orderQuantity : 0;

          return {
            ...variant,
            price: parseFloat(variant.price),
            orderQuantity,
          }
        }).filter(variantEl => variantEl.price > 0 && variantEl.available_inventory> 0);
        this.catelogue.variants = newVariants;

      } catch (error) {
        console.error('Error fetching product:', error);
        this.showAlert(this.$t("CATELOGUE_ITEM.API_ERR.CATELOGUE_FETCH"));
      }finally{
        this.isCatalogLoading = false;
      }
    },
    increaseQuantity(index) {
      console.log(this.catelogue.variants[index]);
      // available_inventory
      if (this.catelogue.variants[index].available_inventory < (this.catelogue.variants[index].orderQuantity + 1)) {
        this.showAlert("Insufficient inventory!");
        return
      }
      this.catelogue.variants[index].orderQuantity++;
    },
    decreaseQuantity(index) {
      if (this.catelogue.variants[index].orderQuantity > 0) {
        this.catelogue.variants[index].orderQuantity--;
      }
    },
    async addToCartHandler() {
      // let existingCart = StorageHelpers.getCartById(this.$route.params.accountId);
      let existingCart = this.currentCart;
      console.log({existingCart});
      existingCart.currency = this.catelogue.price?.split(" ")[1];

      this.catelogue.variants.forEach(variant => {
        if (variant.orderQuantity === 0) {
          let index = existingCart.order_items.findIndex(item => item.varient_id === variant.id);
          if (index !== -1) {
            existingCart.order_items.splice(index, 1);
          }
        }
      });

      this.catelogue.variants.forEach(variant => {
        if (variant.orderQuantity > 0) {
          let existingItem = existingCart.order_items.find(item => item.varient_id === variant.id);
          if (existingItem) {
            existingItem.orderQuantity = variant.orderQuantity;
          } else {
            existingCart.order_items.push({
              item_price: variant.price,
              orderQuantity: variant.orderQuantity,
              catalog_item_id: this.catelogue.id,
              catalog_title: this.catelogue.title,
              varient_id: variant.id,
              varient_title: variant.title,
            });
          }
        }
      });

      // Calculate cart totals
      const cartWithTotalSummary = this.calculateCartTotals(existingCart);
      // StorageHelpers.updateCartById(this.$route.params.accountId,cartWithTotalSummary);
      await this.$store.dispatch("carts/updateCartById",{
        accountId: this.$route.params.accountId,
        cart: cartWithTotalSummary
      })
      bus.$emit('cart_updated_event',cartWithTotalSummary);
      this.showAlert(this.$t("CATELOGUE_ITEM.CART.BTN.ADD"));
    },
    calculateCartTotals(cart) {
      const updatedOrderItems = cart.order_items.map(item => {
        const total = parseFloat(item.item_price) * item.orderQuantity;
        return {
          ...item,
          total: parseFloat(total.toFixed(2))
        };
      });

      const sumTotal = updatedOrderItems.reduce((acc, item) => acc + parseFloat(item.total), 0);

      const sst = this.merchant.sst === 'true' ? parseFloat(sumTotal * 0.08) : 0;  // 8% of total
      const grantTotal = sumTotal + sst;

      return {
        ...cart,
        order_items: updatedOrderItems,
        summary: {
          sumTotal: parseFloat(sumTotal.toFixed(2)),
          sst: parseFloat(sst.toFixed(2)),
          grantTotal: parseFloat(grantTotal.toFixed(2)),
        }
      };
    },
    async orderNowhandler() {
      if (!this.isAddToCartDisabled) {
        await this.addToCartHandler();
      }
      this.$router.push(`/app/accounts/${this.$route.params.accountId}/order`)
    },
    checkCurrentOrderStatus(){
      const cart = StorageHelpers.getCartById(this.$route.params.accountId);

      // don't add new product if already has a pending/completed cart
      const {COMPLETED,PENDING} = SHOP_CONSTANT.ORDER_STATUS
      if ((cart?.summary?.order_status === PENDING)||(cart?.summary?.order_status === COMPLETED)) {
        this.isAddToCartDisabled = true;
      }
      // if the cart already has an active order disable all button
      if (cart?.summary?.order_id) {
        // console.log("Active order found and disabling btn");
        this.isAddToCartDisabled = true;
      }
    },
    isOutsideBusinessHours() {
      const merchant = StorageHelpers.getMerchantById(this.$route.params.accountId);

      const currentDay = new Date().getDay().toString();
      const currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      }); // get current time in "HH:mm" format

      console.log({currentDay,currentTime});

      const businessHour = merchant.business_hours?.find(
        (hour) => hour.day?.toString() === currentDay
      );

      if (businessHour) {
        const { open, close } = businessHour;
        const [openHour, openMinute] = open.split(":").map(Number);
        const [closeHour, closeMinute] = close.split(":").map(Number);
        const [currentHour, currentMinute] = currentTime.split(":").map(Number);

        const isOpen = currentHour > openHour || (currentHour === openHour && currentMinute >= openMinute);
        const isClosed = currentHour < closeHour || (currentHour === closeHour && currentMinute < closeMinute);
        // console.log({isOpen,isClosed});
        return !isOpen || !isClosed;
      } else {
        return true; // disable button if no business hours for the current day
      }
    },

    async getValidImageUrl(url) {
      return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => resolve(url);
          img.onerror = () => resolve(placeholderImage);
          img.src = url;
      });
    },


  }
}
</script>

<style lang="scss" scoped>
  .img_wraper{
      max-width: 400px;
      width: 100%;
      margin: auto;
      padding: var(--p-sm);
      background-color: var(--bg-secondary);
      border-radius: var(--b-md);

      img{
        object-fit: cover;
        height: 350px;
        width: 100%;
      }
  }

  .title_header{
    .description{
      font-weight: 500;
      color: var(--txt-secondary-light);
    }
  }

  .variant_table_container{
    max-width: 100%;
    border-radius: var(--b-md);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    overflow-x: auto;

    .no_item{
      text-align: center;
      margin: 40px auto 40px auto;
    }
  }
  .variants{
    width: 100%;
    border-collapse: collapse;

    thead,tbody,tr{
      border: none;
    }

    .row{
      display: grid;
      grid-template-columns: 1fr 80px 100px 90px;
      gap: 1px;
      align-items: center;
      // min-height: 45px;
      th{
        min-height: 60px;
        display: flex;
        align-items: center;
      }
      td,th{
        border-right: 1px solid #c4bebe;
      }
      td:last-child,th:last-child{
        border: none;
      }
      &:nth-child(even){
        background-color: var(--bg-secondary);
      }

      div{
        padding: 4px 8px;
        border-right: 1px solid #c4bebe;
      }
      div:last-child {
        border-right: none;
      }
      .btns{
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        max-width: 180px;
        margin: auto;
      }
      .icon{
        max-width: 20px;
        max-height: 20px;
        border: 1px solid;
        display: flex;
        border-radius: 50%;
        background-color:  var(--primary-color);
        cursor: pointer;
        svg{
          color: #fff;
        }
      }
      .align_right{
        text-align: right;
      }
    }
    .header{
      background-color: var(--primary-color);
      color: #fff;
    }
    .header div{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header div:first-child{
      border-top-left-radius: var(--b-md);
    }
    .header div:last-child{
      border-top-right-radius: var(--b-md);
    }
  }

  .action_btns{
    display: flex;
    justify-content: space-around;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
</style>