export const TYPES_SHOP = {
    // merchant
    "SET_MERCHANT_UIFLAG":"SET_MERCHANT_UIFLAG",
    "SET_MERCHANT":"SET_MERCHANT",

    // category
    "SET_CATEGORY_UIFLAG":"SET_CATEGORY_UIFLAG",
    "SET_CATEGORY":"SET_CATEGORY",
    "SET_CATALOG_ITEMS":"SET_CATALOG_ITEMS",

    // cart
    "SET_CART_UIFLAG":"SET_CART_UIFLAG",
    "SET_CARTS":"SET_CARTS",
    "UPDATE_CART_BY_ID":"UPDATE_CART_BY_ID",
    "DELETE_CART_BY_ACCOUNTORDER_ID":"DELETE_CART_BY_ACCOUNTORDER_ID",

}