import { TYPES_SHOP } from "../mutationTypes";
import merchantAPI from "../../api/merchantAPI";
import { StorageHelpers } from "../../routes/shop/merchant/merchantHelper";

export const state = {
    // retrive initial sate from local storage
    merchants: StorageHelpers.getAllMerchants() || {address:{}},
    uiFlags: {
        isFetching: false,
        isCreating: false,
        isUpdating: false,
        isDeleting: false,
    },
}

export const getters = {
    getUiFlags($state){
        return $state.uiFlags;
    },
    getMerchants($state){
        return $state.merchants;
    },
    getCurrentMerchant:($state)=>(accountId)=>{

        const defaultMerchant = {
            account_id:accountId,
            name: "",
            logo_url: "",
            phone: "",
            address: {},
            business_hours: [],
            sst: "false"
        }
        const merchant = $state.merchants[accountId] || defaultMerchant;
        return merchant;
    },
};

export const actions = {
    get: async({commit},{accountId})=>{
        commit(TYPES_SHOP.SET_MERCHANT_UIFLAG,{isFetching:true})
        try {
            const { data } = await merchantAPI.getMerchantDetails();
            const merchant = {
                ...data,
                accountId,
            };
            commit(TYPES_SHOP.SET_MERCHANT,merchant)
            commit(TYPES_SHOP.SET_MERCHANT_UIFLAG,{isFetching:false})
        } catch (error) {
            commit(TYPES_SHOP.SET_MERCHANT_UIFLAG,{isFetching:false})
        }
    },
};

export const mutations = {
    [TYPES_SHOP.SET_MERCHANT_UIFLAG](_state,data){
        _state.uiFlags = {
            ..._state.uiFlags,
            ...data,
        }
    },
    [TYPES_SHOP.SET_MERCHANT](_state,merchant){
        _state.merchants = {
            ..._state.merchants,
            [merchant.accountId]:merchant,
        }
    },

};

// add this into store.subscribe
export const merchantSubscriber = ({payload,type},$state) =>{
    const pieces = type.split('/');
    if (pieces.length < 2 || pieces[0] !== 'merchants') {
        return;
    }
    const merchant = payload;
    // save the storage when action is called
    switch (pieces[1]) {
        case TYPES_SHOP.SET_MERCHANT:
            if (merchant.accountId) {
                StorageHelpers.updateMerchantById(merchant.accountId,merchant)
            }

            break;

        default:
            break;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}