const localStorageCartKey = "carts";
const localStorageMerchantKey = "merchants";
const localStorageUserContactInfoKey = "user_contact";


export const SHOP_CONSTANT = {
    ORDER_STATUS:{
        NEW: 'new',
        FRESH: 'fresh',
        PENDING: 'pending',
        PACKED: 'packed',
        PAID: 'paid',
        COMPLETED: 'completed',
        CANCELED: 'cancelled',
        DELIVERED: 'out_for_delivery',
    },
    ORDER_DELIVERY:{
        SELF_COLLECT:"self_collect",
        DELIVERY:"delivery",
    },
    EVENTS:{
        CART_UPDATED:"cart_updated_event",
        ORDER_QUEUE_UPDATED:"order_queue_updated_event",
        ORDER_STATUS_UPDATED: "order_status_updated"
    },
}

export const StorageHelpers = {
    // Read method
    getAllCarts() {
        const storedCarts = localStorage.getItem(localStorageCartKey);
        const carts = storedCarts ? JSON.parse(storedCarts) : {};
        return carts;
    },

    getCartById(account_id) {
        const storedCarts = localStorage.getItem(localStorageCartKey);
        const carts = storedCarts ? JSON.parse(storedCarts) : {};

        const defaultCart = {
            account_id,
            currency: "",
            order_items: [],
            contact: {
                name:"",
                phone_number:""
            },
            summary: {
                grantTotal: 0,
                order_id: null,
                sst: 0,
                sumTotal: 0,
                order_status: SHOP_CONSTANT.ORDER_STATUS.NEW,   // a initial cart it always new
            },
        }

        return carts[account_id] || defaultCart;
    },

    // Write method
    updateCartById(account_id, cart) {
        if (!account_id) {
            return
        }
        // console.log("up card",cart?.summary);
        const storedCarts = localStorage.getItem(localStorageCartKey);
        const carts = storedCarts ? JSON.parse(storedCarts) : {};

        carts[account_id] = cart;
        localStorage.setItem(localStorageCartKey, JSON.stringify(carts));
        bus.$emit(SHOP_CONSTANT.EVENTS.CART_UPDATED,carts);
    },
    deleteCartByAccountId(account_id) {
        const storedCarts = localStorage.getItem(localStorageCartKey);
        const carts = storedCarts ? JSON.parse(storedCarts) : {};

        if (carts[account_id]) {
            delete carts[account_id];
            localStorage.setItem(localStorageCartKey, JSON.stringify(carts));
        }
        bus.$emit(SHOP_CONSTANT.EVENTS.CART_UPDATED,carts);
    },
    deleteCartByAccountIdAndOrderId(accountId,orderId){
        const carts = this.getAllCarts();
        if (carts[accountId] && carts[accountId].summary?.order_id === orderId) {
            // console.log("deleting completed order = ", carts[accountId]);
            delete carts[accountId];
            localStorage.setItem(localStorageCartKey, JSON.stringify(carts));
            bus.$emit(SHOP_CONSTANT.EVENTS.CART_UPDATED,carts);
            return{isDeleted:true}
        }
        return{isDeleted:false}
    },
    getCartByOrderId(orderId){
        const carts = this.getAllCarts();
        for (const accountId in carts) {
            if (carts.hasOwnProperty(accountId)) {
                const order = carts[accountId];
                // Check if the order_id matches
                if (order.summary && order.summary?.order_id === orderId) {
                    return order;
                }
            }
        }
        return {}
    },


    // Merchant Collection
    getAllMerchants() {
        const storedMerchants = localStorage.getItem(localStorageMerchantKey);
        const merchants = storedMerchants ? JSON.parse(storedMerchants) : {};
        return merchants;
    },
    getMerchantById(account_id) {
        const storedMerchants = localStorage.getItem(localStorageMerchantKey);
        const merchants = storedMerchants ? JSON.parse(storedMerchants) : {};

        const defaultMerchant = {
            account_id,
            name: "",
            phone: "",
            address: {},
            business_hours: [],
            sst: "false"
        }
        return merchants[account_id] || defaultMerchant;
    },
    updateMerchantById(account_id, merchant) {
        const storedMerchants = localStorage.getItem(localStorageMerchantKey);
        const merchants = storedMerchants ? JSON.parse(storedMerchants) : {};

        merchants[account_id] = merchant;
        localStorage.setItem(localStorageMerchantKey, JSON.stringify(merchants));
    },

    // user contact info DB
    getUserContactInfo() {
        const storedUser = localStorage.getItem(localStorageUserContactInfoKey);
        const user = storedUser ? JSON.parse(storedUser) : {
            name:"",
            phone_number:"",
            selected_country_code:"+60",
            input_phone_number:"",
            shipment_address:{
                line1:"",
                line2:"",
                city:"",
                state:"",
                postalCode:"",
                country:"Malaysia",
            },
        };
        return user;
    },
    updateUserContactInfo(user) {
        localStorage.setItem(localStorageUserContactInfoKey, JSON.stringify(user));
    },
};
