import Vue from 'vue';
import Vuex from 'vuex';
import merchants, { merchantSubscriber } from "./modules/merchant";
import carts, { cartSubscriber } from "./modules/cart";
import catalog from "./modules/catalog";

// attach vueX with vue
Vue.use(Vuex)
const shopStore = new Vuex.Store({
    modules:{
        merchants,
        catalog,
        carts,
    },
})


// Subscribe to mutations to handle localStorage updates
shopStore.subscribe((mutation, state) => {
    const pieces = mutation.type.split('/');
    if (pieces.length > 1) {
      const module = pieces[0];
      switch (module) {
        case 'merchants':
                // call merchant subscriber method
                merchantSubscriber(mutation, state);
            break;
        case 'carts':
                // call merchant subscriber method
                cartSubscriber(mutation, state);
            break;

        default:
            break;
      }
    }
})

export default shopStore;

