import { TYPES_SHOP } from "../mutationTypes";
import CatelogueItemAPI from "../../api/catelogueItemAPI";

export const state = {
    categories: [],
    catalogItems:[],
    uiFlags: {
        // category
        isFetching: false,
        isCreating: false,
        isUpdating: false,
        isDeleting: false,

        // catalog
        isCatalogItemFetching: false,
        isCatalogItemCreating: false,
        isCatalogItemUpdating: false,
        isCatalogItemDeleting: false,
    },
}

export const getters = {
    getUiFlags($state){
        return $state.uiFlags;
    },
    getCategories($state){
        return $state.categories;
    },
    getCatalogItems($state){
        return $state.catalogItems;
    },
}

export const actions = {
    getCategories: async({commit},)=>{
        commit(TYPES_SHOP.SET_CATEGORY_UIFLAG,{isFetching:true})
        try {
            const {data} = await CatelogueItemAPI.getCatelogueCategories();
            commit(TYPES_SHOP.SET_CATEGORY,data||[])
            commit(TYPES_SHOP.SET_CATEGORY_UIFLAG,{isFetching:false})
            return data||[];
        } catch (error) {
            commit(TYPES_SHOP.SET_CATEGORY_UIFLAG,{isFetching:false})
        }
    },
    getCatalogItemsByCategoryId: async({commit},{category_id})=>{
        commit(TYPES_SHOP.SET_CATEGORY_UIFLAG,{isCatalogItemFetching:true});
        try {
            const {data} = await CatelogueItemAPI.getCatelogueItemsByCategoryId(category_id);
            commit(TYPES_SHOP.SET_CATALOG_ITEMS,data||[]);
            commit(TYPES_SHOP.SET_CATEGORY_UIFLAG,{isCatalogItemFetching:false});
            return data||[];
        } catch (error) {
            commit(TYPES_SHOP.SET_CATEGORY_UIFLAG,{isCatalogItemFetching:false});
        }
    },
};

export const mutations = {
    [TYPES_SHOP.SET_CATEGORY_UIFLAG](_state,data){
        _state.uiFlags = {
            ..._state.uiFlags,
            ...data,
        }
    },
    [TYPES_SHOP.SET_CATEGORY](_state,categories){
        _state.categories = categories;
    },
    [TYPES_SHOP.SET_CATALOG_ITEMS](_state,catalgoItems){
        _state.catalogItems = catalgoItems;
    },

};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}