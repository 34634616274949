import ShopLayout from "./shop/ShopLayout.vue";
import Merchant from "./shop/merchant/Merchant.vue";
import MerchantProductDetails from "./shop/merchant/MerchantProductDetails.vue";
import ProductOrder from "./shop/merchant/ProductOrder.vue";
import PaymentQueue from "./shop/merchant/PaymentQueue.vue";




export const shop_routes = [
    {
        path: '/app/accounts/:accountId',
        component: ShopLayout,
        children: [
            {
                path: '',
                component: Merchant,
            },
            {
                path: 'product',
                component: MerchantProductDetails,
            },
            {
                path: 'order',
                component: ProductOrder,
            },
            {
                path: 'payment',
                component: PaymentQueue,
            },

        ]
    },
];
