<template>
  <div id="shop_layout" class="shop_layout">
    <shop-nav />
    <div>
        <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ShopNav from './ShopNav.vue';
import merchantAPI from "../../api/merchantAPI";



export default {
  components: { ShopNav },
  provide(){
    return{
      merchant: this.merchant
    }
  },
  data(){
    return {
      merchant:{},
    };
  },
  mounted(){
    // this.getMerchant();
    this.$store.dispatch("merchants/get",{accountId:this.$route.params.accountId});
    this.$store.dispatch("catalog/getCategories");
  },
  watch:{
    '$route.params.accountId'(){
      this.$store.dispatch("merchants/get",{accountId:this.$route.params.accountId});
      this.$store.dispatch("catalog/getCategories");
    },
  },
  async created() {
    // await this.getMerchant();
  },
  methods:{
    /*
    getMerchant() {
      const urlParams = new URLSearchParams(window.location.search);

      // "?id=456&name=daniel&phone_number=69154547&account_id=123"

      const existingMerchant = JSON.parse(localStorage.getItem('merchant') || '{}');

      // Check if account_id has changed
      const newAccountId = this.$route.params.accountId;
      if (newAccountId && newAccountId !== existingMerchant.accountId) {
        // Clear existing merchant data if accountId has changed
        localStorage.removeItem('merchant');
        this.merchant.id = urlParams.get('id') || '';
        this.merchant.name = urlParams.get('name') || '';
        this.merchant.phone_number = urlParams.get('phone_number') || '';
        this.merchant.accountId = newAccountId || '';
        this.merchant.description = urlParams.get('description') || '';
        this.merchant.sst = true;
      } else {
        // Update merchant data based on URL parameters
        this.merchant.id = urlParams.get('id') || existingMerchant.id || '';
        this.merchant.name = urlParams.get('name') || existingMerchant.name || '';
        this.merchant.phone_number = urlParams.get('phone_number') || existingMerchant.phone_number || '';
        this.merchant.accountId = newAccountId || existingMerchant.accountId || '';
        this.merchant.description = urlParams.get('description') || existingMerchant.description || '';
        this.merchant.sst = true;
      }

      // Update localStorage
      localStorage.setItem('merchant', JSON.stringify(this.merchant));
    },
    */
    /*
    async getMerchant() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('name') || '';
        const { data } = await merchantAPI.getMerchantDetails(name);
        this.merchant = {
          ...data,
          accountId : this.$route.params.accountId,
        };
        console.log(this.merchant);
        // Update localStorage
        localStorage.setItem('merchant', JSON.stringify(this.merchant));
      } catch (error) {
        console.error('Error fetching merchant:', error);
      }
    },
    */


  },
}
</script>

<style lang="scss">
.shop_layout{
  min-width: 320px;
  font-size: var(--fs-lg);

  // global css classes
  .shop_label{
    font-weight: 600;
    color: var(--txt-secondary);
    margin-bottom: var(--space-small);
  }
  .px_md{padding-left: 10px; padding-right: 10px;}
  .py_md{padding-top: 10px; padding-bottom: 10px;}
  .section_gap_x{margin-left: 3%; margin-right: 3%;}
  .section_gap_y{margin-top: 25px; margin-bottom: 25px;}

  .sp_title_lg{ font-size: 1.8rem;}
  .sp_title_md { font-size: 1.6rem;}

}
</style>