import ShopApiClient from './ShopApiClient';

class OrderAPi extends ShopApiClient {
  constructor() {
    super('orders', { accountScoped: true });
  }

  createOrder(payload){
    return axios.post(`${this.url}`,payload);
  }
  cancelOrder(id){
    return axios.post(`${this.url}/cancel_order`,{id});
  }
  getOrdersAhead(order_id){
    return axios.get(`${this.url}/get_orders_ahead?id=${order_id}`);
  }
  confirmOrder(order_id){
    return axios.post(`${this.url}/confirm_order`,{id:order_id});
  }
  getOrdersStatusByContactId(contact_id){
    return axios.get(`${this.url}/orders_by_contact?contact_id=${contact_id}`);
  }
  getPaymentLink(order_id,payload={order_date:"",}){
    // return axios.post(`${this.apiVersion}/generate_tekkis_payment`,{account_id,...payload});
    return axios.post(`${this.url}/${order_id}/tekkis_payments`,payload);
  }
}

export default new OrderAPi();
