
import { SHOP_CONSTANT, StorageHelpers } from '../../routes/shop/merchant/merchantHelper';
import BaseActionCableShop from './BaseActionCableShop';
import AuthAPI from '../../../dashboard/api/auth';
import Cookies from 'js-cookie';

class ActionCableShop extends BaseActionCableShop {
  constructor(app, contactId, pubsubToken,user={}) {
    const { websocketURL = '' } = window.peasyConfig || {};
    super(app, contactId, pubsubToken, user,websocketURL);
    // this.CancelTyping = [];
    this.events = {
      'order.completed': this.onOrderCompleted,
      'order.canceled': this.onOrderCancelled,
      'order.cancelled': this.onOrderCancelled,
      'order.packed': this.onOrderPacked,
      'order.paid': this.onOrderPaid,
      'order.delivered': this.onOrderDelivered,
    };
  }


  onOrderCompleted = data => {
    console.log({data},"onOrderCompleted by action cable");
    bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_QUEUE_UPDATED,data);
  };
  onOrderCancelled = data => {
    console.log({data},"onOrderCancelled by action cable");
    bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_QUEUE_UPDATED,data);
  };
  onOrderPacked = data => {
    console.log({data},"onOrderPacked by action cable");
    bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_STATUS_UPDATED,data);
  };
  onOrderPaid = data => {
    console.log({data},"onOrderPaid by action cable");
    bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_STATUS_UPDATED,data);
  };
  onOrderDelivered = data => {
    console.log("onOrderDelivered = ",{data});
    bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_STATUS_UPDATED,data);
  };

}

export default {
  init() {
    const pathSegments =  window.location.pathname.split('/');
    const accountId = pathSegments.length > 3 ? pathSegments[3] : null;
    // get contactId and pubsubToken from localStorage, then init
    const orderCart = StorageHelpers.getCartById(accountId);
    const PeasySalesOrigins= ['chatdev.peasy.ai','chat.peasy.ai']

    const contactId = orderCart?.summary?.contact_id;
    // if origin is shop, then use summary else use  AuthAPI.getPubSubToken()
    // const pubsubToken = orderCart?.summary?.pubsub_token;

    const {pubsub_token: authPubsubToken} = Cookies.getJSON('user') || {};
    const pubsubToken = PeasySalesOrigins.includes(window.location.host)
    ? authPubsubToken
    : orderCart?.summary?.pubsub_token;



    const user = AuthAPI.getCurrentUser();

    const authUser = {
      user_id: user?.id,
      account_id: user?.account_id,
      test:"test data"
    }

    // if (contactId && pubsubToken) {
      const actionCable = new ActionCableShop(window.SHOP,contactId,pubsubToken,authUser);
      return actionCable;
    // }
  },
};
