<template>
  <nav class="nav">
      <div>
        <span class="brand">
            <router-link :to="home_url">
                <!-- Peasy -->
                <img :src="peasylogo" alt="peasy-logo">
            </router-link>
        </span>
      </div>
      <div>
        <ul class="items">
            <!-- <li v-if="accountId"><router-link :to="order_url">Order | {{ cart.currency }}{{ cart.summary.grantTotal }}</router-link></li> -->
            <li v-if="accountId" @click.prevent="navigateBasedOnCartStatus">Order | {{ cart.currency }}{{ cart.summary.grantTotal }}</li>
        </ul>
      </div>
    </nav>
</template>

<script>
import {StorageHelpers,SHOP_CONSTANT} from "./merchant/merchantHelper";
import peasylogo from "../../../dashboard/assets/images/external/peasylogo.png"


export default {
    name:"ShopNav",
    data(){
        return {
            cart: {
                currency: "MYR",
                summary: {
                    grantTotal: 0
                },
            },
            peasylogo,
        }
    },
    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        order_url() {
            return `/app/accounts/${this.accountId}/order`;
        },
        home_url() {
            return `/app/accounts/${this.accountId}`;
        },
    },
    async mounted() {
        this.updateOrderAmount();
        // whenever cart update event,
        bus.$on('cart_updated_event', (data) => {
            this.updateOrderAmount();
        });
    },
    watch:{
        '$route.params.accountId'(newValue,oldValue){
            this.updateOrderAmount();
        },
    },
    methods:{
        updateOrderAmount(){
            const existCart = StorageHelpers.getCartById(this.$route.params.accountId);
            if (existCart.summary?.grantTotal && typeof existCart.summary?.grantTotal === 'number') {
                this.cart = existCart
            }else{
                this.cart.currency = "MYR";
                this.cart.summary.grantTotal = 0;
            }
        },
         navigateBasedOnCartStatus() {
            const cart = StorageHelpers.getCartById(this.$route.params.accountId);
            if (cart?.summary?.order_id && (cart?.summary?.order_status !== SHOP_CONSTANT.ORDER_STATUS.CANCELED)) {
                this.$router.push(`/app/accounts/${this.accountId}/payment`);
            }else{
                this.$router.push(`/app/accounts/${this.accountId}/order`);
            }
        }

    },
}
</script>

<style lang="scss">
.nav{
    position: sticky;
    top: 0;
    background-color: var(--brand-color);
    border-bottom: 2px solid var(--primary-color);
    padding: 0.5rem 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brand{
        font-size: 2.4rem;
        font-weight: bolder;
        a{
            color: #000;
            font-size: 25px;
            img{
                    width: 100px;
            }
        }
    }
    .items{
        list-style: none;
        margin: 0;
        font-weight: bold;
        li{
            font-size: 1.5rem;
            cursor: pointer;
            a{
                color:  #6d6868;
            }
        }
    }
}
</style>