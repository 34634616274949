<template>
  <div class="section_gap_x">
    <section v-if="!isReadyToAcceptPayment && cart.summary.order_id" class="container">
        <div class="title_header text_center">
          <p class="shop_label sp_title_lg">{{$t("CATELOGUE_ORDER_PAYMENT.HEADER.LABEL")}} {{cart.summary.order_id}}</p>
          <p class="sp_title_md description">{{$t("CATELOGUE_ORDER_PAYMENT.HEADER.DESCRIPTION")}}</p>
        </div>
        <div>
            <div class="queue_number">
                {{count}}
            </div>
        </div>
        <div>
            <p class="sp_title_md description">{{distanceBasedMsg}}</p>
        </div>
        <div v-if="!isHideAllBtn">
            <shop-button
                v-if="isCancelOrReorderBtn === 'confirm'"
                className="confirm_btn"
                :isLoading="isConfirmOrderLoading"
                @click="confirmFinalOrderHandler"
            >Confirm</shop-button>

            <shop-button
                className="mx-auto"
                @click="cancelOrderHandler"
                :disabled="isReadyToAcceptPayment"
                :isLoading="isOrderCancelLoading"
            >{{$t("CATELOGUE_ORDER_PAYMENT.CANCEL_BTN")}}</shop-button>
        </div>
    </section>

    <section v-if="cart.summary.allowToReOrder" class="container section_gap_y">
        <div class="title_header text_center section_gap_y">
          <p class="sp_title_md description">{{$t("PRODUCT.ORDER_MSG.CANCEL_MSG")}}</p>
        </div>
        <shop-button
            :isLoading="isConfirmOrderLoading"
            className="section_gap_y"
            @click="reOrderHandler"
        >{{$t("PRODUCT.ORDER_MSG.RE_ORDER_BTN")}}</shop-button>
    </section>

    <section v-if="isReadyToAcceptPayment" class="container container_payment">
        <div class="title_header text_center">
          <p class="shop_label sp_title_lg">{{$t("CATELOGUE_ORDER_PAYMENT.HEADER.LABEL")}} {{cart.summary.order_id}}</p>
          <p class="sp_title_md description">{{$t("CATELOGUE_ORDER_PAYMENT.HEADER.PAYMENT_DESCRIPTION")}}</p>
        </div>
        <div class="qr_code_wrapper">

        </div>
    </section>



    <section>
        <order-list />
    </section>
  </div>
</template>

<script>
import ShopButton from '../../../components/ShopButton.vue';
import orderApi from "../../../api/orderApi";
import {StorageHelpers,SHOP_CONSTANT} from "./merchantHelper";
import alertMixin from 'shared/mixins/alertMixin';
import OrderList from './OrderList.vue';

export default {
    components: { ShopButton, OrderList,  },
    mixins:[alertMixin],
    data() {
        return {
            isReadyToAcceptPayment:false,
            count: null,
            cart: {summary:{}},
            isOrderCancelLoading:false,
            distanceBasedMsg: '',
            isCancelOrReorderBtn:false,
            isConfirmOrderLoading: false,
            isHideAllBtn: false,
        };
    },
    mounted(){
        this.getOrdersAhead();
        bus.$on(SHOP_CONSTANT.EVENTS.ORDER_QUEUE_UPDATED,(data)=>{
            console.log('webhook data = ',data);
            this.getOrdersAhead(data);
        }),
        this.trackUserAndConfirmOrder();
    },
    computed:{
        isReadyToAcceptPayment(){
            return this.count <= 3;
        },
    },
    watch:{
      '$route.params.accountId'(){
        this.getOrdersAhead();
      },
    },
     beforeDestroy() {
        clearInterval(this.trackUserInterval);
    },
    methods:{
        async getOrdersAhead(webHookData=null){
            try {
                const accountId = this.$route.params.accountId;
                this.isCancelOrReorderBtn = null;

                this.cart = StorageHelpers.getCartById(accountId);

                if (!this.cart?.summary?.order_id) {
                    if (this.cart.summary.allowToReOrder) {
                        this.isCancelOrReorderBtn = 're-order';
                    }else{
                        // this.showAlert(this.$t("CATELOGUE_ORDER_PAYMENT.ALERT_MSG.EMPTY_ORDER"));
                        console.log("No order id found");
                        // return to order page
                        this.$router.push(`/app/accounts/${this.$route.params.accountId}`)
                    }

                    return
                }else if (this.cart.summary.order_status === SHOP_CONSTANT.ORDER_STATUS.COMPLETED) {
                    this.isReadyToAcceptPayment = true;
                    return
                }


                const {data} = await orderApi.getOrdersAhead(this.cart.summary.order_id);
                this.count = data.count;

                // if no order id just return
                console.log("bef the",StorageHelpers.getCartById(accountId)?.summary);
                if (!StorageHelpers.getCartById(accountId)?.summary?.order_id) {
                    console.log("Returning hardly");
                    return
                }
                const merchant = StorageHelpers.getMerchantById(accountId);
                const userLocation = await this.getUserLocation();
                const distance = this.getDistanceFromLatLonInKm(merchant.address,userLocation);
                console.log({shopLocation:merchant.address,userLocation,distance}); // don't delete

                // internal sub method to handle order pack and deliver. Move it outside to reuse to call when webhook triger
                const handlePackAndDeliver = async (isWithinGeoFence) =>{
                    // !isWithinGeoFence OR !georestrict
                    if (!isWithinGeoFence || !merchant.georestrict) {  // Decision 02
                        // if order=packed,geofence=off and delivery=false "Please proceed to collect your from our shop at {{address}"
                        // this.distanceBasedMsg = 'Your order is being proceed. Plesae, go to shop and collect the order';
                        if ((this.cart.summary.order_status === SHOP_CONSTANT.ORDER_STATUS.FRESH)&& !merchant.payment_before_order) {
                            // If geolocation is not on, immediately call confirm_order api endpoint.
                            const response = await orderApi.confirmOrder(this.cart.summary.order_id)
                            this.cart.summary.order_status = SHOP_CONSTANT.ORDER_STATUS.PENDING;
                            console.log("HERE");
                            StorageHelpers.updateCartById(this.$route.params.accountId,this.cart);
                        }
                    }
                    // order is packed: webhook.delivery = "self_collect", and order id is for this order
                    const {ORDER_DELIVERY:{DELIVERY,SELF_COLLECT},ORDER_STATUS:{PACKED,PAID,DELIVERED}} = SHOP_CONSTANT;
                    const cartStatus = this.cart?.summary?.order_status;
                    const deliveryType = this.cart?.summary?.delivery;
                    console.log({cartStatus,DELIVERED});
                    if (cartStatus === PACKED) {
                        // if (!merchant.delivery) {}
                        if (deliveryType === DELIVERY) {
                            // open shipment form
                            this.distanceBasedMsg = `We are preparing your order for shipment`;
                        }else if (deliveryType === SELF_COLLECT) {
                            // disable all buttons: cancel btn
                            this.isHideAllBtn = true;
                            this.distanceBasedMsg = `Please proceed to collect your order from our shop at ${this.formatAddress(merchant.address)}`;
                        }
                        // this.notifyShopMessage('Order is packed',this.distanceBasedMsg)
                    }
                    // hide button when out for delivered
                    if (cartStatus === DELIVERED || webHookData?.order_status === DELIVERED) {
                        console.log({cartStatus,DELIVERED});
                        this.isHideAllBtn = true;
                    }
                    // if order is paid, hide cancel button
                    if (cartStatus === PAID) {
                        this.isHideAllBtn = true;
                    }
                    // write more logic here
                }
                // new condition and workflow
                if (merchant.georestrict) {
                    // if georestrict, distance based conditions
                    if (data.count <= 5) {
                        // start traking when order queue is less than 5 as process start,
                        if (!this.cart.summary.processStartTime) {
                            this.cart.summary.processStartTime = Date.now();
                            StorageHelpers.updateCartById(this.$route.params.accountId,this.cart);
                        }
                        // if user is 20m radious from shop, show confirm btn
                        if (distance <= 20) {
                            // Set order status to pending. Ask customer to go and collect the order
                            this.distanceBasedMsg =merchant.delivery ? "Your order is being processed.": `Please proceed to our shop at ${this.formatAddress(merchant.address)}, your order will be ready shortly`;
                            if (this.cart.summary.order_status === SHOP_CONSTANT.ORDER_STATUS.FRESH) {
                                // also call confirm_order API to change status on database
                                const response = await orderApi.confirmOrder(this.cart.summary.order_id)
                                // this.notifyShopMessage('Order is packed',this.distanceBasedMsg)
                                // call payment API and ask for payment
                                if (merchant.payment_before_order) {
                                    await this.completePayment(this.cart)
                                }else{
                                    this.cart.summary.order_status = SHOP_CONSTANT.ORDER_STATUS.PENDING;
                                    StorageHelpers.updateCartById(this.$route.params.accountId,this.cart);
                                }
                            }
                        }else{
                            // Give customer 5 (?)minutes. Ask customer to go to area or order will be cancel
                            const processStartTime = this.cart.summary.processStartTime || Date.now();
                            const add15Minutes = new Date(processStartTime + 15 * 60000).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

                            const createdTimeDiffMinutes = Math.floor((Date.now() - this.cart.summary.createdAt) / (1000 * 60));
                            const processTimeDiffMinutes = Math.floor((Date.now() - this.cart.summary.processStartTime) / (1000 * 60));
                            console.log({processTimeDiffMinutes,createdTimeDiffMinutes});
                            this.distanceBasedMsg =merchant.delivery ? "Your order is being processed.": `Please proceed to our shop at ${this.formatAddress(merchant.address)} within the next ${15 - processTimeDiffMinutes} minutes. Your order will be automatically cancelled by ${add15Minutes} if you are not present.`;
                            // this.notifyShopMessage('Order is packed',this.distanceBasedMsg)
                            if (processTimeDiffMinutes > 15) {
                                // handlePackAndDeliver(true) // I think it is wrong logic
                            }

                            // cancel the order if not within 15 minutes after process start, delivery:false, order created atleast 15 mins ago
                            if ((processTimeDiffMinutes > 15)&& !merchant.delivery && (createdTimeDiffMinutes > 15)) {
                                // allow atleast 5 minutes to cancel order
                                const {data:cancelData} = await orderApi.cancelOrder(this.cart.summary.order_id)
                                if (cancelData.success) {
                                    // remove the order_id from cart and add as allowToReOrder:true
                                    this.cart.summary.allowToReOrder = true;
                                    this.cart.summary.order_id = null;
                                    this.cart.summary.order_status = SHOP_CONSTANT.ORDER_STATUS.CANCELED;

                                    StorageHelpers.updateCartById(accountId,this.cart);
                                    this.isCancelOrReorderBtn = 're-order';
                                }
                            }

                        }
                    }else{
                        // when queue is nore than 5
                        // this.distanceBasedMsg =merchant.delivery ? "Your order is being processed.": `Your order is being processed.`;
                    }

                }else{
                    // call confirm_order API if merchant.georestrict = false;
                    // if not georestrict, ->pack->delivery->ask delivery address
                    await handlePackAndDeliver(false);
                }

                // hide cancel or confirm button if status is paid
                if (this.cart.summary.order_status === SHOP_CONSTANT.ORDER_STATUS.PAID) {
                    this.isHideAllBtn = true;
                }

            } catch (error) {
                console.log(error);
                // this.showAlert(`Failed to track order queue`);
                this.showAlert(this.$t("CATELOGUE_ORDER_PAYMENT.ALERT_MSG.TRACK_ORDER_ERR"));
            }
        },
        async cancelOrderHandler(){
            try {
                this.isOrderCancelLoading = true;
                const {data} = await orderApi.cancelOrder(this.cart.summary.order_id);
                if (data.success) {
                    // delete the cart from cart list
                    const existingCart = StorageHelpers.deleteCartByAccountId(this.$route.params.accountId);
                    bus.$emit('cart_updated_event',existingCart);
                    this.$router.push(`/app/accounts/${this.$route.params.accountId}`)
                    this.showAlert(this.$t("CATELOGUE_ORDER_PAYMENT.ALERT_MSG.ORDER_CANCEL_SUCCESS"));
                }else{
                    this.showAlert(this.$t("PRODUCT.ORDER_MSG.CANCEL_FAIL_MSG"));
                }
                this.isOrderCancelLoading = false;
            } catch (error) {
                console.log(error);
                this.isOrderCancelLoading = false;
                this.showAlert(this.$t("CATELOGUE_ORDER_PAYMENT.ALERT_MSG.ORDER_CANCEL_FAIL"));
            }

        },
         async confirmFinalOrderHandler(){
             try {
                this.isConfirmOrderLoading = true;
                 const {data} = await orderApi.confirmOrder(this.cart.summary.order_id);
                if (data?.success) {
                    this.showAlert(this.$t("PRODUCT.ORDER_MSG.CONFIRM_SUCCESS_MSG"));
                }
                this.isConfirmOrderLoading = false;
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t("PRODUCT.ORDER_MSG.CONFIRM_ERR_MSG"));
                this.isConfirmOrderLoading = false;
            }
        },
         async reOrderHandler(){
             try {
                this.isConfirmOrderLoading = true;
                const userInfo = StorageHelpers.getUserContactInfo();
                // call API to create order
                const payload = {
                    currency : this.cart.currency,
                    order_items: this.cart.order_items.map((item)=>{
                        return {
                            item_price: item.item_price,
                            quantity:item.orderQuantity,
                            catalog_item_id: item.catalog_item_id,
                        }
                    }),
                    contact: {
                        name: userInfo.name,
                        phone_number: userInfo.phone_number,
                    }
                }
                const {data} = await orderApi.createOrder(payload);

                if (data.id) {
                    // store the order id
                    this.cart.summary.order_id = data.id;
                    this.cart.summary.contact_id = data.contact.id;
                    this.cart.summary.pubsub_token = data.contact.pubsub_token;
                    this.cart.summary.order_status = SHOP_CONSTANT.ORDER_STATUS.PENDING;
                    this.cart.summary.allowToReOrder = false;
                    this.cart.summary.createdAt = Date.now();
                    this.cart.summary.processStartTime = null;

                    StorageHelpers.updateCartById(this.$route.params.accountId,this.cart);

                    this.$router.push(`/app/accounts/${this.$route.params.accountId}/payment`).catch(err => {});
                    location.reload();
                }

                this.showAlert(this.$t("PRODUCT.ORDER_MSG.CREATE_SUCCESS_MSG"));

                this.isConfirmOrderLoading = false;
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t("PRODUCT.ORDER_MSG.CREATE_ERR_MSG"));
                this.isConfirmOrderLoading = false;
            }
        },
        getUserLocation() {
            const options = {
                enableHighAccuracy: true,
                maximumAge: 0
            }
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(position => {
                        const userLocation = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }
                        resolve(userLocation);
                    },
                    error => {
                        reject(error);
                    },
                    options,
                );
            });
        },

        getDistanceFromLatLonInKm(merchantLocation,userLocation) {
            const {latitude:lat1,longitude:lon1} = merchantLocation;
            const {latitude:lat2,longitude:lon2} = userLocation;
            var R = 6371; // Radius of the earth in km
            var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
            var dLon = this.deg2rad(lon2-lon1);
            var a =
                Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
                Math.sin(dLon/2) * Math.sin(dLon/2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            var d = R * c; // Distance in km
            return d*1000;  // distance in meter by multiplying by 1000
        },

        deg2rad(deg) {
            return deg * (Math.PI/180)
        },
        formatAddress(address) {
            const { city, line1, line2, state } = address;
            const parts = [];
            if (line1) parts.push(line1);
            if (line2) parts.push(line2);
            if (city) parts.push(city);
            if (state) parts.push(state);
            return parts.join(', ');
        },
        async trackUserAndConfirmOrder(){
            // check conditions and call confirm order API
            this.trackUserInterval = setInterval(async() => {
                console.log('conditionally call confirm_order API');
                    const accountId = this.$route.params.accountId;
                if (!StorageHelpers.getCartById(accountId)?.summary?.order_id) {
                    console.log("Returning hardly");
                    return
                }
                const merchant = StorageHelpers.getMerchantById(accountId);
                const cart = StorageHelpers.getCartById(accountId);
                if (merchant.georestrict) {
                    const userLocation = await this.getUserLocation();
                    const distance = this.getDistanceFromLatLonInKm(merchant.address,userLocation);
                    console.log({shopLocation:merchant.address,userLocation,distance}); // don't delete
                    // check:order.status=fresh &&distance > radius, then
                    // call confirm API if within 20 meters
                    if ((cart.summary.order_status === SHOP_CONSTANT.ORDER_STATUS.FRESH) && (distance <= 20)) {
                            // call confirm API
                            const response = await orderApi.confirmOrder(cart.summary.order_id)
                            if (merchant.payment_before_order) {
                                await this.completePayment(cart)
                            }
                            cart.summary.order_status = SHOP_CONSTANT.ORDER_STATUS.PENDING;
                            console.log("HERE contunuous update");
                            StorageHelpers.updateCartById(this.$route.params.accountId,cart);
                    }

                }


            }, 10*1000);
        },
        async completePayment(cart){
            console.log("check completePayment",cart);
            const merchant = StorageHelpers.getMerchantById(this.$route.params.accountId);
            // geo on, status fresh, receive payment before order, pay now, local update paid
            if (merchant.georestrict && merchant.payment_before_order && (cart.summary.order_status === SHOP_CONSTANT.ORDER_STATUS.FRESH)) {
                const {data:payRes} = await orderApi.getPaymentLink(cart.summary.order_id,{
                    order_date: new Date().toISOString(),
                })
                console.log("callled payment",payRes);

                if (payRes.payment_link) {
                    // update paid status
                    cart.summary.order_status = SHOP_CONSTANT.ORDER_STATUS.PAID;
                    StorageHelpers.updateCartById(this.$route.params.accountId,cart);
                    console.log("update paid status local");
                    const link = document.createElement('a');
                    link.href = payRes.payment_link;
                    link.rel = 'noopener noreferrer';
                    document.body.appendChild(link)
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },




        // end methods
    },

}
</script>

<style lang="scss" scoped>
.text_center{
    text-align: center;
}
.title_header{
  .description{
    font-weight: 500;
    color: var(--txt-secondary-light);
  }
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}

.container{
    // calc = padding - navHeight
    // min-height: calc(100vh - 2* 2rem - 15px);
    display: grid;
    grid-template-rows: 1fr 1.5fr 1fr;
    justify-items: center;
    align-items: center;
}

.queue_number{
    font-size: 10rem;
    font-weight: 700;
    color: var(--txt-secondary-light);
}
.container_payment{
    grid-template-rows: 1fr 2fr;

    .qr_code_wrapper{
        background-color: var(--bg-secondary);
        border-radius: 8px;
        width: 300px;
        height: 300px;
        margin-bottom: 25px;
    }
}

.confirm_btn{
    margin: 12px auto;
}
</style>