import Vue from 'vue';
import VueRouter from 'vue-router';
import { sync } from 'vuex-router-sync';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import ShopApp from "../shopApp/ShopApp.vue";
import { shop_routes } from '../shopApp/routes/shop.routes';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon';
import i18n_shop from '../shopApp/i18n_shop';
import ActionCableShop from "../shopApp/helpers/actionCable/ActionCableShop";
import  shopStore  from "../shopApp/store/store";
import WootUiKit from '../dashboard/components';

// configs
Vue.use(VueI18n);
const i18nConfigForShop = new VueI18n({
    locale: 'en',
    messages: i18n_shop,
  });


// middlewares
Vue.use(VueRouter);
Vue.use(WootUiKit);
Vue.use(Vuelidate);
window.bus = new Vue();

// global components
Vue.component('fluent-icon', FluentIcon);



// router configure
const shop_router = new VueRouter({
    mode: 'history',
    routes: [
        // { path: '/', redirect: '/accounts/:accountId' },
        ...shop_routes,
        // { path: '/test', component: TestPage },
    ]
});

sync(shopStore,shop_router)

window.onload = () => {
    window.SHOP = new Vue({
        router: shop_router,
        store: shopStore,
        i18n: i18nConfigForShop,
        components: { ShopApp },
        template: '<ShopApp />',
    }).$mount('#shop_app');
    ActionCableShop.init()
};