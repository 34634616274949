<template>
  <div class="section_gap_x section_gap_y">
    <section v-if="!isFormShow">
      <div>
        <div class="title_header section_gap_y">
          <p class="shop_label sp_title_lg">
            {{$t("CATELOGUE_ORDER_PAYMENT.HEADER.LABEL")}}
            {{cart.summary.order_id}}
          </p>
          <p class="sp_title_md description">
            {{$t("CATELOGUE_ORDER_PAYMENT.HEADER.TOTAL_AMOUNT")}}
            {{cart.currency}}{{cart.summary.grantTotal}}
          </p>
        </div>
      </div>
      <!-- add remove button here  -->
      <div class="variant_table_container">
        <table class="variants">
          <thead>
            <tr class="row header">
              <th>{{$t("CATELOGUE_ITEM.VARIANT.TABLE_HEADER.TITLE_ITEM")}}</th>
            <th>{{$t("CATELOGUE_ITEM.VARIANT.TABLE_HEADER.PRICE")}}</th>
            <th>{{$t("CATELOGUE_ITEM.VARIANT.TABLE_HEADER.QUANTITY")}}</th>
            <th>{{$t("CATELOGUE_ITEM.VARIANT.TABLE_HEADER.TOTAL_PRICE")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in cart.order_items" :key="index" class="row">
              <td class="variant_title">
                {{ item.catalog_title }} - {{ item.varient_title }}
                <woot-button
                  v-tooltip.top="$t('CANNED_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="small"
                  icon="dismiss-circle"
                  class-names="grey-btn btn-width-auto"
                  @click="removeItemFromCart(item)"
                />
                  <!-- :is-loading="loading[cannedItem.id]" -->
              </td>
              <td class="align_right">{{ item.item_price }}</td>
              <td class="align_right">{{ item.orderQuantity }}</td>
              <td class="align_right">{{ item.total }}</td>
            </tr>
            <tr class="row">
              <td class="empty_td"></td>
              <td class="empty_td"></td>
              <!-- <td>Total</td> -->
              <td>{{$t("CATELOGUE_ITEM.VARIANT.SUMMARY.TOTAL")}}</td>
              <td class="align_right">{{cart.summary.sumTotal}}</td>
            </tr>
            <tr class="row">
              <td class="empty_td"></td>
              <td class="empty_td"></td>
              <td>{{$t("CATELOGUE_ITEM.VARIANT.SUMMARY.SST")}}</td>
              <td class="align_right">{{cart.summary.sst}}</td>
            </tr>
            <tr class="row">
              <td class="empty_td"></td>
              <td class="empty_td"></td>
              <td>{{$t("CATELOGUE_ITEM.VARIANT.SUMMARY.GRANT_TOTAL")}}</td>
              <td class="align_right">{{cart.summary.grantTotal}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="section_gap_y">
        <shop-button
          className="mx-auto"
          :disabled="!isOrderBtnEnabled || isOrderDisabled || isExistOrderStatus()"
          @click="confirmOrderHandler"
        >
        <!-- {{$t("CATELOGUE_ITEM.VARIANT.BTNS.CONFIRM_ORDER")}} -->
        Continue
        </shop-button>
        <p v-if="isOrderDisabled" style="text-align:center;">{{$t("CATELOGUE_ITEM.VARIANT.BTNS.BUSINESS_HOUR_MSG")}}</p>
      </div>
    </section>

    <section v-if="isFormShow">
      <div class="shipment_address">
        <div>
          <p class="shop_label">{{$t("CATELOGUE_ITEM.VARIANT.FORM.NAME.LABEL")}}</p>
          <input
            v-model="user.name"
            class="field"
            type="text"
            :placeholder="$t('CATELOGUE_ITEM.VARIANT.FORM.NAME.PLACEHOLDER')"
          >
          <span v-if="$v.user.name.$error" class="error">
            {{$t("CATELOGUE_ITEM.VARIANT.FORM.NAME.ERR_MSG")}}
          </span>
        </div>
        <div>
          <div>
              <p class="shop_label">{{$t("CATELOGUE_ITEM.VARIANT.FORM.PHONE.LABEL")}}</p>
              <div class="phone_number_container">
                <select
                  @input="onCountryCodeChange"
                  v-model="user.selected_country_code"
                  :disabled="false"
                >
                  <option
                    v-for="code in phone_code_list"
                    :key="code.name"
                    :value="code.dial_code"
                  >
                    {{code.emoji}} {{ code.dial_code }}
                  </option>
                </select>
                <div class="phone_number">
                  <input
                    v-model="user.input_phone_number"
                    class="field"
                    type="number"
                    :placeholder="$t('CATELOGUE_ITEM.VARIANT.FORM.PHONE.PLACEHOLDER')"
                    @change="onPhoneNumberChange"
                    @blur="$v.user.phone_number.$touch"
                  >
                  <span v-if="$v.user.phone_number.$error" class="error">{{$t("CATELOGUE_ITEM.VARIANT.FORM.PHONE.ERR_MSG")}}</span>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div v-if="isShipmentAddressFormOpen(merchant)" class="shipment_address">
        <div>
          <p class="shop_label">{{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.LINE1.LABEL")}}</p>
              <input
                  v-model="user.shipment_address.line1"
                  class="field"
                  type="text"
                  :placeholder="$t('CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.LINE1.PLACEHOLDER')"
              >
            <span v-if="$v.user.shipment_address.line1.$error" class="error">
            {{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.LINE1.ERR_MSG")}}
          </span>
        </div>

        <div>
          <p class="shop_label">{{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.LINE2.LABEL")}}</p>
          <input
            v-model="user.shipment_address.line2"
            class="field"
            type="text"
            :placeholder="$t('CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.LINE2.PLACEHOLDER')"
          >
          <span v-if="$v.user.shipment_address.line2.$error" class="error">
            {{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.LINE2.ERR_MSG")}}
          </span>
        </div>
        <div>
          <p class="shop_label">{{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.CITY.LABEL")}}</p>
          <input
            v-model="user.shipment_address.city"
            class="field"
            type="text"
            :placeholder="$t('CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.CITY.PLACEHOLDER')"
          >
          <span v-if="$v.user.shipment_address.city.$error" class="error">
            {{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.CITY.ERR_MSG")}}
          </span>
        </div>
        <div>
          <p class="shop_label">{{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.STATE.LABEL")}}</p>
          <input
            v-model="user.shipment_address.state"
            class="field"
            type="text"
            :placeholder="$t('CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.STATE.PLACEHOLDER')"
          >
          <span v-if="$v.user.shipment_address.state.$error" class="error">
            {{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.STATE.ERR_MSG")}}
          </span>
        </div>
        <div>
          <p class="shop_label">{{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.POSTAL_CODE.LABEL")}}</p>
          <input
            v-model="user.shipment_address.postal_code"
            class="field"
            type="text"
            :placeholder="$t('CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.POSTAL_CODE.PLACEHOLDER')"
          >
          <span v-if="$v.user.shipment_address.postal_code.$error" class="error">
            {{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.POSTAL_CODE.ERR_MSG")}}
          </span>
        </div>
        <div>
          <p class="shop_label">{{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.COUNTRY.LABEL")}}</p>
          <input
            v-model="user.shipment_address.country"
            class="field"
            type="text"
            :placeholder="$t('CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.COUNTRY.PLACEHOLDER')"
          >
          <span v-if="$v.user.shipment_address.country.$error" class="error">
            {{$t("CATELOGUE_ITEM.VARIANT.FORM.SHIPMENT_ADDRESS.COUNTRY.ERR_MSG")}}
          </span>
        </div>

      </div>


      <div class="section_gap_y">
        <shop-button
          className="mx-auto"
          @click="continueConfirmOrderHandler"
          :isLoading="isOrderCreateLoading"
        >
          <!-- :disabled="!merchant.posibble_to_order" -->
        <!-- {{$t("CATELOGUE_ITEM.VARIANT.FORM.SUBMIT_BTN")}} -->
        Submit Order
        </shop-button>
      </div>
    </section>
    <section>
        <order-list />
    </section>
  </div>
</template>

<script>
import ShopButton from '../../../components/ShopButton.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import orderApi from "../../../api/orderApi";
import {SHOP_CONSTANT,StorageHelpers} from "./merchantHelper";
import alertMixin from 'shared/mixins/alertMixin';
import ActionCableShop from "../../../helpers/actionCable/ActionCableShop";
import OrderList from './OrderList.vue';
import {phone_code_list} from "../../../../dashboard/routes/auth/country_code";

const isPhoneNumber = (value) => {
  const phoneNumberRegex = /^\+\d+$/; // Allows + sign and optional space
  return phoneNumberRegex.test(value);
};

export default {
  components: { ShopButton, OrderList },
  mixins:[alertMixin],
  // inject:['merchant'],
  data() {
    // const merchant = StorageHelpers.getMerchantById(this.$route.params.accountId);
    const user = StorageHelpers.getUserContactInfo() || {};
    if (!user.shipment_address) {
      user.shipment_address={
          line1:"",
          line2:"",
          city:"",
          state:"",
          postal_code:"",
          country:"Malaysia",
      }
    }
    return {
      actionCableInstance:null,
      // merchant,
      user: user,

      cart: {
        contact: {
            // name:"",
            // phone_number:""
        },
        order_items:[],
        summary:{
          order_id: null,
          order_status:null,
          grantTotal: 0,
        },
      },

      isFormShow:false,
      isOrderCreateLoading:false,
      isOrderBtnEnabled: false,
      phone_code_list,

    };
  },
  computed:{
    merchant(){
      const newMerchant = this.$store.getters["merchants/getCurrentMerchant"](this.$route.params.accountId);
      return newMerchant;
    },
    currentCart(){
      const currentCart = this.$store.getters["carts/getCurrentCart"](this.$route.params.accountId);
      return currentCart;
    },
    isOrderBtnEnabled(){
      return this.cart.summary?.grantTotal > 0;
    },
    isOrderDisabled() {
      return this.isOutsideBusinessHours();
    },
  },
  mounted(){
    // this.cart = this.calculateCartTotals(StorageHelpers.getCartById(this.$route.params.accountId));
    this.cart = this.calculateCartTotals(this.currentCart);
    this.isOrderBtnEnabled = this.cart?.summary?.grantTotal > 0;
  },
  watch:{
    // update cart when account change
    '$route.params.accountId':function(newValue,oldValue){
      // this.cart = this.calculateCartTotals(StorageHelpers.getCartById(this.$route.params.accountId));
      this.cart = this.calculateCartTotals(this.currentCart);
    },
    // end watch
  },
  validations: {
    user: {
      name: {
        required,
      },
      phone_number: {
        required,
        isPhoneNumber,
      },
      shipment_address: {
        line1: {
          required
        },
        line2: {required},
        city: {
          required
        },
        state: {
          required
        },
        postal_code: {
          required
        },
        country: {
          required
        }
      },

    }
    // end validation
  },
  methods:{
    async createOrder(){
      console.log("Click createOrder()");
      /*
      this.$v.$touch();
      if (this.$v.$error) {
        return
      }
      */


     try {
        this.$v.user.name.$touch();
        this.$v.user.phone_number.$touch();
        if (this.merchant.delivery) {
         console.log("Checking shipment validating");
          // check shipment, else not
          this.$v.user.shipment_address.$touch();
        }
        if (this.$v.user.name.$invalid || this.$v.user.phone_number.$invalid) {
          return
        }
        // Check for shipping address errors
         if (this.merchant.delivery && this.$v.user.shipment_address.$invalid) {
           console.log(this.$v.user);
           return;
         }
        this.isOrderCreateLoading = true;

        // store updated user name and phone, (with country code and input phone number)
        StorageHelpers.updateUserContactInfo(this.user);

        // call API to create order
        const payload = {
          currency : this.cart.currency,
          order_items: this.cart.order_items.map((item)=>{
            return {
              item_price: item.item_price,
              quantity:item.orderQuantity,
              catalog_item_id: item.varient_id,
            }
          }),
          contact: {
            name: this.user.name,
            phone_number: this.user.phone_number,
          },
          // shipment_address:this.user.shipment_address,
        }
        if (this.merchant.delivery) {
          payload.shipment_address = this.user.shipment_address;
        }

        const {data} = await orderApi.createOrder(payload);

        if (data.id) {
          // store the order id
          this.cart.summary.order_id = data.id;
          this.cart.summary.contact_id = data.contact.id;
          this.cart.summary.pubsub_token = data.contact.pubsub_token;
          this.cart.summary.createdAt = Date.now();
          // if merchant.georestrict, then order_status=fresh
          // initially set status fresh, later based on condition, set
          // this.cart.summary.order_status = SHOP_CONSTANT.ORDER_STATUS.PENDING;
          this.cart.summary.order_status = SHOP_CONSTANT.ORDER_STATUS.FRESH;
          // if not georestrict on, then Call confirm API, else check on PaymentQueue.vue component
          if (!this.merchant.georestrict) {
            await orderApi.confirmOrder(data.id)
          }

          // StorageHelpers.updateCartById(this.$route.params.accountId,this.cart);

          await this.$store.dispatch("carts/updateCartById",{
            accountId: this.$route.params.accountId,
            cart: this.cart,
          })

          this.actionCableInstance = ActionCableShop.init(data.contact.id, data.contact.pubsub_token);
          this.isFormShow = false;

          // before redirect: check if payment_before_order=true, & georestrict=false then call API to get payment_link and redirect to payment link
          if (this.merchant.payment_before_order &&  !this.merchant.georestrict) {
            const {data:payRes} = await orderApi.getPaymentLink(this.cart.summary.order_id,{
              order_date: new Date().toISOString(),
            })
            if (payRes.payment_link) {
              const link = document.createElement('a');
              link.href = payRes.payment_link;
              // link.target = "_blank";
              link.rel = 'noopener noreferrer';
              document.body.appendChild(link)
              link.click();
              document.body.removeChild(link);
            }else{
              // delete the order from localstore
              // const {isDeleted} = StorageHelpers.deleteCartByAccountIdAndOrderId(this.$route.params.accountId,this.cart.summary.order_id);
              await this.$store.dispatch("carts/deleteCartByAccountIdAndOrderId",{
                accountId: this.$route.params.accountId,
                order_id: this.cart.summary.order_id,
              })
              // error message that can't take payment right now
              const payErrMsg = 'We are facing technical difficulties with our payment gateway at the moment, please try again later';
              this.showAlert(payErrMsg);
              this.$router.push(`/app/accounts/${this.$route.params.accountId}`).catch(err => {});
            }
            this.isOrderCreateLoading = false;
            return
          }


          this.$router.push(`/app/accounts/${this.$route.params.accountId}/payment`).catch(err => {});
          location.reload();
        }
        this.isOrderCreateLoading = false;
        this.showAlert(this.$t("PRODUCT.ORDER_MSG.CREATE_SUCCESS_MSG"));
      } catch (error) {
        this.showAlert(this.$t("PRODUCT.ORDER_MSG.CREATE_ERR_MSG"));
        this.isOrderCreateLoading = false;
        console.log(error.response,{isOrderCreateLoading:this.isOrderCreateLoading});
        if (error.response.data?.error?.top_up?.length) {
          this.showAlert(this.$t("PRODUCT.ORDER_MSG.CREATE_ERR_MSG_TOPUP"));
        }
        else if (error.response.data?.payment_errors) {
          const payErrMsg = 'We are facing technical difficulties with our payment gateway at the moment, please try again later';
          this.showAlert(payErrMsg);
          this.$router.push(`/app/accounts/${this.$route.params.accountId}`).catch(err => {});
        }
      }

    },
    async confirmOrderHandler(){
      this.isFormShow = true;
    },
    async continueConfirmOrderHandler(){
      // this.$v.$touch();
      // if (this.$v.$error) {
      //   return
      // }
      this.createOrder();
    },

    calculateCartTotals(cart) {
      // navigate to payment if oder is pending or completed
      const {COMPLETED,PENDING} = SHOP_CONSTANT.ORDER_STATUS
      if ((cart?.summary?.order_status === PENDING)||(cart?.summary?.order_status === COMPLETED)) {
        this.$router.push(`/app/accounts/${this.$route.params.accountId}/payment`);
        return
      }

      const updatedOrderItems = cart.order_items.map(item => {
        const total = parseFloat(item.item_price) * item.orderQuantity;
        return {
          ...item,
          total: parseFloat(total.toFixed(2))
        };
      });

      const sumTotal = updatedOrderItems.reduce((acc, item) => acc + parseFloat(item.total), 0);

      const sst = this.merchant.sst === 'true' ? parseFloat(sumTotal * 0.08) : 0;  // 8% of total
      const grantTotal = sumTotal + sst;

      return {
        ...cart,
        order_items: updatedOrderItems,
        summary: {
          ...cart.summary,
          sumTotal: parseFloat(sumTotal.toFixed(2)),
          sst: parseFloat(sst.toFixed(2)),
          grantTotal: parseFloat(grantTotal.toFixed(2)),
        }
      };
    },

    isOutsideBusinessHours() {
      const currentDay = new Date().getDay().toString();
      const currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      }); // get current time in "HH:mm" format

      console.log({currentDay,currentTime});

      const businessHour = this.merchant.business_hours?.find(
        (hour) => hour.day?.toString() === currentDay
      );

      if (businessHour) {
        const { open, close } = businessHour;
        const [openHour, openMinute] = open.split(":").map(Number);
        const [closeHour, closeMinute] = close.split(":").map(Number);
        const [currentHour, currentMinute] = currentTime.split(":").map(Number);

        const isOpen = currentHour > openHour || (currentHour === openHour && currentMinute >= openMinute);
        const isClosed = currentHour < closeHour || (currentHour === closeHour && currentMinute < closeMinute);
        // console.log({isOpen,isClosed});
        return !isOpen || !isClosed;
      } else {
        return true; // disable button if no business hours for the current day
      }
    },

    isExistOrderStatus(){
      // console.log("ck disabl", this.cart.summary);
      // disable confirm order btn if order status is not cancelled
      if (this.cart.summary?.order_status) {
        if (this.cart.summary?.order_status !== SHOP_CONSTANT.ORDER_STATUS.CANCELED) {
          return true;
        }
      }
    },
    isShipmentAddressFormOpen(merchant){
      return merchant?.delivery;
    },

    onCountryCodeChange(event){
      this.user.selected_country_code = event.target.value;
      this.user.phone_number = `${event.target.value}${this.user.input_phone_number}`;
    },
    onPhoneNumberChange(e){
      this.user.input_phone_number = e?.target?.value;
      this.user.phone_number = `${this.user.selected_country_code}${e?.target?.value}`;
    },
    async removeItemFromCart(item){
      if(item.varient_id){
        // dispatch to remove item from cart
        console.log(222,this.currentCart);
        const tepmCart = {...this.currentCart};
        tepmCart.order_items =  tepmCart.order_items.filter(orderEl=>orderEl.varient_id !== item.varient_id);
        const calcCard = this.calculateCartTotals(tepmCart);
        console.log({tepmCart,routId:this.$route.params.accountId});
        await this.$store.dispatch("carts/updateCartById",{
          accountId: this.$route.params.accountId,
          cart: calcCard,
        });
        this.cart = calcCard;
        // not passing full card list
        bus.$emit(SHOP_CONSTANT.EVENTS.CART_UPDATED,{});
      }
    },
    // end of methods
  },
}
</script>

<style lang="scss" scoped>
.title_header{
  .description{
    font-weight: 500;
    color: var(--txt-secondary-light);
  }
}

.mx-auto{
  margin-left: auto;
  margin-right: auto;
}

  .variant_table_container{
    max-width: 100%;
    border-radius: var(--b-md);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    overflow-x: auto;
  }
  .variants{
    width: 100%;
    border-collapse: collapse;

    .variant_title{
      button{
        width: 40px;
      }
    }

    thead,tbody,tr{
      border: none;
    }

    .row{
      display: grid;
      grid-template-columns: 1fr 80px 100px 90px;
      gap: 1px;
      align-items: center;
      // min-height: 45px;
      th{
        min-height: 60px;
        display: flex;
        align-items: center;
      }
      td,th{
        border-right: 1px solid #c4bebe;
      }
      td:last-child,th:last-child{
        border: none;
      }
      &:nth-child(even){
        background-color: var(--bg-secondary);
      }

      .empty_td{
        min-height: 40px;
      }




      .align_right{
        text-align: right;
      }
    }
    .header{
      background-color: var(--primary-color);
      color: #fff;
    }
    .header div{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header div:first-child{
      border-top-left-radius: var(--b-md);
    }
    .header div:last-child{
      border-top-right-radius: var(--b-md);
    }
  }

.field{
  margin-bottom: 0;
}
.error{
  color: red;
}

.phone_number_container{
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;

  select{
    margin: 0;
  }
  .phone_number{
    margin: 0;
  }
}
.shipment_address{
  div{
    margin-top: 5px;
  }
}

</style>