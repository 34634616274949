import { StorageHelpers } from "../../routes/shop/merchant/merchantHelper";
import { TYPES_SHOP } from "../mutationTypes";

export const state = {
    // retrive initial sate from local storage
    carts: StorageHelpers.getAllCarts() || {},
    uiFlags: {
        isFetching: false,
        isCreating: false,
        isUpdating: false,
        isDeleting: false,
    },
}

export const getters = {
    getUiFlags($state){
        return $state.uiFlags;
    },
    getCarts($state){
        return $state.carts;
    },
    getCurrentCart:($state)=>(accountId)=>{

        const defaultCart = {
            order_items: [],
            contact: {},
            summary: {
                order_id: null,
                order_status:null,
                grantTotal: 0,
            }
        }
        const cart = $state.carts[accountId] || defaultCart;
        return cart;
    },
};


export const actions = {
    get: async({commit},)=>{
        commit(TYPES_SHOP.SET_CART_UIFLAG,{isFetching:true})
        try {
            const carts = StorageHelpers.getAllCarts();
            console.log("cmt",{carts});
            commit(TYPES_SHOP.SET_CARTS,carts)
            commit(TYPES_SHOP.SET_CART_UIFLAG,{isFetching:false})
            return carts;
        } catch (error) {
            commit(TYPES_SHOP.SET_CART_UIFLAG,{isFetching:false})
        }
    },
    updateCartById: async({commit},{accountId,cart})=>{
        if (!accountId) {
            return
        }
        commit(TYPES_SHOP.SET_CART_UIFLAG,{isUpdating:true})
        try {
            // const {data} = await CatelogueItemAPI.getCatelogueCategories();
            commit(TYPES_SHOP.UPDATE_CART_BY_ID,{accountId,cart})
            commit(TYPES_SHOP.SET_CART_UIFLAG,{isUpdating:false})
            return cart;
        } catch (error) {
            commit(TYPES_SHOP.SET_CART_UIFLAG,{isUpdating:false})
        }
    },
    deleteCartByAccountIdAndOrderId: async({commit},{accountId,order_id})=>{
        commit(TYPES_SHOP.SET_CART_UIFLAG,{isDeleting:true})
        try {
            // const {data} = await CatelogueItemAPI.getCatelogueCategories();
            commit(TYPES_SHOP.DELETE_CART_BY_ACCOUNTORDER_ID,{accountId,order_id})
            commit(TYPES_SHOP.SET_CART_UIFLAG,{isDeleting:false})
            return cart;
        } catch (error) {
            commit(TYPES_SHOP.SET_CART_UIFLAG,{isDeleting:false})
        }
    },
};


export const mutations = {
    [TYPES_SHOP.SET_CART_UIFLAG](_state,data){
        _state.uiFlags = {
            ..._state.uiFlags,
            ...data,
        }
    },
    [TYPES_SHOP.SET_CARTS](_state,data){
        console.log("dispat setc",data);
        _state.carts = data;
    },
    [TYPES_SHOP.UPDATE_CART_BY_ID](_state,data){
        const {accountId,cart} = data;

        _state.carts = {
            ..._state.carts,
            [accountId]: cart,
        }
    },
    [TYPES_SHOP.DELETE_CART_BY_ACCOUNTORDER_ID](_state,data){
        const {accountId,order_id} = data;

        const temCarts = {
            ..._state.carts
        }
        if (temCarts[accountId] && temCarts[accountId].summary?.order_id === order_id) {
            delete temCarts[accountId];
            _state.carts = temCarts;
        }

    },
    // end mutation
};


// add this into store.subscribe
export const cartSubscriber = ({payload,type},$state) =>{
    const pieces = type.split('/');
    if (pieces.length < 2 || pieces[0] !== 'carts') {
        return;
    }
    const cart = payload;
    // save the storage when action is called
    switch (pieces[1]) {
        case TYPES_SHOP.UPDATE_CART_BY_ID:
            if (cart.accountId) {
                console.log("cartSubscriber = ",{cart});
                StorageHelpers.updateCartById(cart.accountId,cart.cart)
            }
            break;
        case TYPES_SHOP.DELETE_CART_BY_ACCOUNTORDER_ID:
            let {accountId:del_accountId,order_id:del_order_id} = payload
            if (del_accountId && del_order_id) {
                console.log("deleting");
                StorageHelpers.deleteCartByAccountIdAndOrderId(del_accountId,del_order_id)
            }
            break;

        default:
            break;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
