import {SHOP_CONSTANT,StorageHelpers} from "../routes/shop/merchant/merchantHelper"
import alertMixin from 'shared/mixins/alertMixin';
import orderApi from "../api/orderApi"

export const globalShopMixins = {
    mixins:[alertMixin],
    mounted(){
        console.log("globalShopMixins mounted");
        bus.$on(SHOP_CONSTANT.EVENTS.ORDER_QUEUE_UPDATED, (data) => {
            this.orderQUeueUpdateHandler(data);
            // this.orderStatusUpdateHandler(data);
        });
        bus.$on(SHOP_CONSTANT.EVENTS.ORDER_STATUS_UPDATED, (data) => {
            this.orderStatusUpdateHandler(data);
        });
        this.syncOrderStatus();
    },
    computed: {
        accountId() {
            return this.$route.params.accountId;
        }
    },
    watch:{
        '$route.params.accountId'(newValue,oldValue){
            this.syncOrderStatus();
        },
    },
    methods:{
        orderQUeueUpdateHandler(completedOrder){
            const {ORDER_STATUS} = SHOP_CONSTANT;
            const accountId = this.$route.params.accountId;
            console.log({completedOrder});
            // handle if order is completed
            if (completedOrder.order_status === ORDER_STATUS.COMPLETED) {
                console.log("deleting order =",completedOrder.id);
                const {isDeleted} = StorageHelpers.deleteCartByAccountIdAndOrderId(completedOrder.account_id,completedOrder.id);
                if (isDeleted) {
                    this.showAlert("Your order is completed");
                    // Check the current route
                    const currentRoute = this.$route.path;
                    // If the user is on "/app/accounts/4/payment", navigate to "/app/accounts/4/order"
                    if (currentRoute === `/app/accounts/${completedOrder.account_id}/payment`) {
                        this.$router.push(`/app/accounts/${completedOrder.account_id}`);
                    }
                }
            }
            // handle if order is cancelled
            if (completedOrder.order_status === ORDER_STATUS.CANCELED) {
                console.log("deleting order =",completedOrder.id);
                const {isDeleted} = StorageHelpers.deleteCartByAccountIdAndOrderId(completedOrder.account_id,completedOrder.id);
                if (isDeleted) {
                    this.showAlert("Your order is cancelled");
                    // Check the current route
                    const currentRoute = this.$route.path;
                    // If the user is on "/app/accounts/4/payment", navigate to "/app/accounts/4/order"
                    if (currentRoute === `/app/accounts/${completedOrder.account_id}/payment`) {
                        this.$router.push(`/app/accounts/${completedOrder.account_id}`);
                    }
                }
            }

        },
        // handle order status updated
        orderStatusUpdateHandler(orderData){
            const {PACKED,PAID,DELIVERED} = SHOP_CONSTANT.ORDER_STATUS;
            console.log({orderData,DELIVERED});
            const myOrder = StorageHelpers.getCartByOrderId(orderData.id);
            if (!myOrder?.summary?.order_id) {
                return
            }
            if (orderData.order_status === PACKED){
                myOrder.summary.delivery = orderData.delivery;
                myOrder.summary.order_status = orderData.order_status;
                console.log({myOrder,fr:"PACKED"});
                StorageHelpers.updateCartById(orderData.account_id,myOrder);
                this.notifyShopMessage('Order Ready for Collection', 'Your order has been packed and is now ready for collection at your convenience.');
                bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_QUEUE_UPDATED,orderData);
            }else if (orderData.order_status === PAID){
                myOrder.summary.delivery = orderData.delivery;
                myOrder.summary.order_status = orderData.order_status;
                console.log({myOrder,fr:"PAID"});
                StorageHelpers.updateCartById(orderData.account_id,myOrder)

            }else if (orderData.order_status === DELIVERED){
                myOrder.summary.delivery = orderData.delivery;
                myOrder.summary.order_status = orderData.order_status;
                console.log({myOrder,fr:"DELIVERED"});
                StorageHelpers.updateCartById(orderData.account_id,myOrder)
                this.showAlert('Your order is now out for delivery');
                this.notifyShopMessage('Order Out for Delivery', 'Your order is now out for delivery');
                bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_QUEUE_UPDATED,orderData); // to hide "cancel btn"
            }
            // triger an event to update the UI in any page
            // bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_QUEUE_UPDATED,orderData);
        },
        async syncOrderStatus(){
            try {
                const cart = StorageHelpers.getCartById(this.accountId);
                // console.log({cart});
                if (cart?.summary?.contact_id) {
                    const {data} = await orderApi.getOrdersStatusByContactId(cart?.summary?.contact_id)
                    // console.log({data});
                    if (Array.isArray(data)) {
                        data.forEach(([orderId,status])=>{
                            // update the status
                            if (cart?.summary?.order_id === orderId) {
                                // if status is completed or cancelled, the delete else update
                                const {CANCELED,COMPLETED} = SHOP_CONSTANT.ORDER_STATUS
                                if ((status === COMPLETED) || (status === CANCELED)) {
                                    StorageHelpers.deleteCartByAccountIdAndOrderId(this.accountId,orderId)
                                }else{
                                    cart.summary.order_status = status;
                                    StorageHelpers.updateCartById(this.accountId,cart)
                                }
                            }
                        })
                        // triger an event to update the UI in any page
                        bus.$emit(SHOP_CONSTANT.EVENTS.ORDER_QUEUE_UPDATED,{});
                    }

                }
            } catch (error) {
                console.log(error);
            }
        },
         // show notification and sound notitify
         notifyShopMessage(title, message) {
            try {
                // Notification API
                if ('Notification' in window) {
                    if (Notification.permission === "granted") {
                        new Notification(title, { body: message });
                    } else {
                        Notification.requestPermission().then(permission => {
                            if (permission === "granted") {
                                new Notification(title, { body: message });
                            } else {
                                console.warn("Notification permission denied.");
                            }
                        });
                    }
                } else {
                    console.warn("Notification API not supported in this browser.");
                }

                // Speech API
                if ('speechSynthesis' in window) {
                    const utterance = new SpeechSynthesisUtterance(message);

                    // Define a function to select the voice
                    const setVoice = () => {
                        const voices = window.speechSynthesis.getVoices();
                        const femaleVoice = voices.find(voice => voice.name.toLowerCase().includes('female')) || voices[0];
                        if (femaleVoice) {
                            utterance.voice = femaleVoice;
                        }
                        window.speechSynthesis.speak(utterance);
                        console.log("Speech Synthesis triggered:", message);
                    };

                    // Check if voices are already loaded
                    if (window.speechSynthesis.getVoices().length !== 0) {
                        setVoice();
                    } else {
                        // Wait for the voices to be loaded
                        window.speechSynthesis.onvoiceschanged = setVoice;
                    }
                } else {
                    console.warn("Speech Synthesis API not supported in this browser.");
                }
                /*
                if ('speechSynthesis' in window) {
                    const utterance = new SpeechSynthesisUtterance(message);
                     // Fetch available voices and select a female voice dynamically
                    const voices = window.speechSynthesis.getVoices();
                    const femaleVoice = voices.find(voice => voice.name.toLowerCase().includes('female')) || voices[0];
                    if (femaleVoice) {
                        utterance.voice = femaleVoice;
                    }
                    // speak now
                    window.speechSynthesis.speak(utterance);
                    console.log("Speech Synthesis triggered:", message);
                } else {
                    console.warn("Speech Synthesis API not supported in this browser.");
                }
                */

            } catch (error) {
                console.log(error);
            }
        },

        // end of methods
    },
}