import ar from './locale/ar';
import en from './locale/en';
import id from './locale/id';
import ja from './locale/ja';
import vi from './locale/vi';
import zh_CN from './locale/zh_CN';
import zh_TW from './locale/zh_TW';
export default {
    ar,
    en,
    id,
    ja,
    vi,
    zh_CN,
    zh_TW,
}