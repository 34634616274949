import ShopApiClient from './ShopApiClient';

class MerchantAPI extends ShopApiClient {
  constructor() {
    super('merchant', { accountScoped: true });
  } 
  
  getMerchantDetails(merchantName){
    // return axios.get(`${this.url}?name=${merchantName}`);
    return axios.get(`${this.url}`);
  }
}

export default new MerchantAPI();
