<template>
    <div id="shop_app" class="app-wrapper app-root">
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
        <snackbar-container />
    </div>
</template>

<script>    
import SnackbarContainer from '../dashboard/components/SnackbarContainer.vue';
import {globalShopMixins} from "./mixins/globalMixins"

export default {
    name:"shopapp",
    mixins:[globalShopMixins],
    components: {
        SnackbarContainer,
    },
   
   methods:{
    
   },
}
</script>

<style>
@import './global.css';

</style>