<template>
  <button :class="[computedClass, className]" @click="handleClick" :disabled="isLoading || disabled">
    <span v-if="isLoading" class="loader"></span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ShopButton',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedClass() {
        const loadingCls = this.isLoading ? 'show_loader':""
      return `btn btn-${this.type} ${loadingCls}`;
    },
  },
  methods: {
    handleClick() {
      if (!this.isLoading || !this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style scoped>
.btn {
  position: relative;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}
.show_loader{
    padding-left: 28px;
}
.btn span {
  margin-right: 10px;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.btn-success {
  background-color: var(--success-color);
  color: white;
}

.btn-danger {
  background-color: var(--danger-color);
  color: white;
}

.btn[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.loader {
  position: absolute;
  left: 13px;
  top: 18px;
  transform: translateY(-50%);
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--primary-color);
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
</style>
