<template>
  <section v-if="orderList.length"  class="section_gap_y">
    <p class="shop_label page-title">{{$t("PRODUCT.ORDER_LIST.TITLE")}}</p>
    <div class="variant_table_container">
        <table class="variants">
          <thead>
            <tr class="row header">
              <th>{{$t("PRODUCT.ORDER_LIST.TABLE.HEADER.NAME")}}</th>
              <th>{{$t("PRODUCT.ORDER_LIST.TABLE.HEADER.AMOUNT")}}</th>
              <th>{{$t("PRODUCT.ORDER_LIST.TABLE.HEADER.STATUS")}}</th>
              <!-- <th>Order Amount</th> -->
              <!-- <th>Status</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, accountId) in orderList" :key="accountId" class="row">
              <td>{{ order.merchantName }}</td>
              <td class="align_right">{{ order.orderAmount }}</td>
              <td><router-link :to="order.order_url">{{ order.status }}</router-link></td>
            </tr>
          </tbody>
        </table>
      </div>
  </section>
</template>

<script>
import {StorageHelpers,SHOP_CONSTANT} from "./merchantHelper";

export default {
    name:"OrderList",
    data(){
        return {
            orderList: [],
        }
    },
    mounted() {
        this.formatOrderList();
        bus.$on(SHOP_CONSTANT.EVENTS.CART_UPDATED, (data) => {
          this.formatOrderList();
        });
    },
    watch:{
      '$route.params.accountId'(){
        this.formatOrderList();
      },
    },
    methods:{
        formatOrderList(){
            const carts = StorageHelpers.getAllCarts() || {};
            const merchants = StorageHelpers.getAllMerchants() || {};

            const {ORDER_STATUS:{COMPLETED,NEW,PENDING,FRESH,PACKED,CANCELED,PAID}} = SHOP_CONSTANT;
            // delete current account cart
            delete carts[this.$route.params.accountId]
  
            this.orderList = Object.keys(carts).map(accountId => {
                // const status = carts[accountId]?.summary?.order_status === PENDING
                // ? "Still Pending"
                // : carts[accountId]?.summary?.order_status === COMPLETED
                // ? "Collect your order now"
                // : "Complete your order";
                const orderStatus = carts[accountId]?.summary?.order_status;
                let status = "Complete your order";
                if (orderStatus === PENDING) {
                    status = "Still Pending";
                } else if (orderStatus === COMPLETED) {
                    status = "Collect your order now";
                } else if (orderStatus === CANCELED) {
                    status = "Order has been canceled";
                } else if (orderStatus === FRESH) {
                    status = "Order is fresh";
                }else if (orderStatus === NEW) {
                    status = "Order is new";
                }else if (orderStatus === PACKED) {
                    status = "Order is packed";
                } else if (orderStatus === PAID) {
                    status = "Order is paid";
                }

                const order_url = carts[accountId]?.summary?.order_id
                ? `/app/accounts/${accountId}/payment`
                : `/app/accounts/${accountId}/order`;

                return {
                    merchantName: merchants[accountId]?.name || '',
                    orderAmount: carts[accountId]?.summary?.grantTotal || 0,
                    status: status,
                    order_url,
                };
            });
        },

    },
}
</script>

<style lang="scss" scoped>

  .variant_table_container{
    max-width: 100%;
    border-radius: var(--b-md);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    overflow-x: auto;
  }
  .variants{
    width: 100%;
    border-collapse: collapse;

    thead,tbody,tr{
      border: none;
    }

    .row{
      display: grid;
      grid-template-columns: 1fr 80px 100px 90px;
      gap: 1px;
      align-items: center;
      // min-height: 45px;
      th{
        min-height: 60px;
        display: flex;
        align-items: center;
      }
      td,th{
        border-right: 1px solid #c4bebe;
      }
      td:last-child,th:last-child{
        border: none;
      }
      &:nth-child(even){
        background-color: var(--bg-secondary);
      }

      div{
        padding: 4px 8px;
        border-right: 1px solid #c4bebe;
      }
      div:last-child {
        border-right: none;
      }


      .align_right{
        text-align: right;
      }
    }
    .header{
      background-color: var(--primary-color);
      color: #fff;
    }
    .header div{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header div:first-child{
      border-top-left-radius: var(--b-md);
    }
    .header div:last-child{
      border-top-right-radius: var(--b-md);
    }
  }

</style>