<template>
<div>
  <div v-if="!uiFlags.isFetching" class="info section_gap_x">
    <div >
      <div class="logo_wrapper">
        <div></div>
        <div>
          <SHImageVue :src="merchant.logo_url" />
        </div>
        <div></div>
      </div>
      <p class="shop_label">{{merchant.name}}</p>
      <p>{{merchant.description}}</p>
      <div v-if="merchant.address" class="description px_md py_md">
        <p>
          {{merchant.address.line1}},
          {{merchant.address.line2}},
          {{merchant.address.postcode}},
          {{merchant.address.city}},
          {{merchant.address.state}}
        </p>
      </div>
    </div>
    <div class="py_md">
      <p class="shop_label">
        {{$t("MERCHANT.INFO.PHONE_LABEL")}}
        <a style="font-weight:400;" :href="'tel:' + merchant.phone">{{merchant.phone}}</a>
      </p>
      <div v-if="!merchant.delivery" class="current_queue">
        <p>{{$t("MERCHANT.INFO.QUEQE_LABEL")}}: </p>
        <p>{{merchant.order_queue_count || 0}}</p>
      </div>
    </div>
  </div>

  <div class="item_categories section_gap_x" id="item_categories">
      <!-- v-for="category in categories" -->
      <!-- v-for="(category,idx) in displayCategories" -->
    <button
      v-for="(category,idx) in allCatalogItems"
      :class="isActiveCategory(category) ? 'active':''"
      :key="category.id"
      @click="onCategorySingleSelect(idx)"
    >{{category.category}}</button>
  </div>

<!--
  <section v-if="isShowByCatagory" class="section_gap_x section_gap_y">
    <section
      class="section_gap_x catalog_container"
      v-for="catalog in allCatalogItems"
      :data-category-id="catalog.categoryId"
      :id="`catalog_container-${catalog.categoryId}`"
      ref="catalogSections"
      :key="catalog.categoryId"
    >
      <div>
        <ul class="categories">
          <li
            class="category_card"
            v-for="(catelogueItem,idx) in filterStockedItems(catalog.catalogItems)"
            :key="catelogueItem.id"
            :class="{
              'last_category_card': idx === filterStockedItems(catalog.catalogItems).length - 1
            }"
            :data-catalogItem-id="catelogueItem.id"
            :data-category-id="catalog.categoryId"
            :ref="getCatalogRef(catalog)"
            @click="goToProduct(catelogueItem.id)"
          >
            <SHImageVue className="card_img" :src="catelogueItem.image_link " />
            <h4 class="shop_label title">{{catelogueItem.title}}</h4>
            <div class="footer">
              <span>{{catelogueItem.price}}</span>
              <span class="icon">
                <fluent-icon icon="cart" />
              </span>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <loader-round v-if="uiFlagsCatalog.isCatalogItemFetching || uiFlags.isFetching"  />
  </section>
   -->

  <section ref="catalogDisplayContainer" class="section_gap_x section_gap_y" id="catalogDisplayContainer">
    <div ref="topSkeleton"></div>
    <ul class="categories">
      <li
        v-for="(catalog) in flattenedDisplayCatalogItems"
        class="category_card"
        :class="{
          'last_category_card':catalog.isLastItemOfCatagory
        }"
        :key="catalog.id"
        :id="`catalog_container-${catalog.isFirstItemOfCatagory && catalog.categoryId}`"
        :data-category-id="catalog.categoryId"
        :data-catalogItem-id="catalog.id"
        :ref="getCatalogRef(catalog)"
        @click="goToProduct(catalog.id)"
      >
        <SHImageVue className="card_img" :src="catalog.image_link " />
        <h4 class="shop_label title">{{catalog.title}}</h4>
        <div class="footer">
          <span>{{catalog.price}}</span>
          <span class="icon">
            <fluent-icon icon="cart" />
          </span>
        </div>
      </li>
    </ul>
    <div ref="bottomSkeleton"></div>
    <loader-round v-if="uiFlagsCatalog.isCatalogItemFetching || uiFlags.isFetching || isCatelogueItemsFetching"  />
  </section>



</div>
</template>

<script>
import {mapGetters} from "vuex";
// import CatelogueItemAPI from "../../../api/catelogueItemAPI";
// import merchantAPI from "../../../api/merchantAPI";
// import {StorageHelpers} from "./merchantHelper";
import alertMixin from 'shared/mixins/alertMixin';
import LoaderRound from '../components/LoaderRound.vue';
import SHImageVue from '../components/SHImage.vue';


export default {
  // inject:['merchant'],
  components:{
    LoaderRound,
    SHImageVue,
  },
  mixins:[alertMixin],
  data() {
    return {
      isMerchantFetching: false,
      // merchant:{address:{}},
      // categories:[],
      // catelogueItems:  [],
      isCatelogueItemsFetching: false,
      selectedCategoryId:"",
      allCatalogItems:[
        // {categoryId:1,category:"Drink",catalogItems:[]},
      ],
      observer: null,
      displayCatalogItems:[],
      instockedCategories: [],
      isCatagoryClick: false,
      isDataLoaded: false,
      isCtgClickByUser: false,
    };
  },
  computed:{
    ...mapGetters({
      uiFlags: "merchants/getUiFlags",
      uiFlagsCatalog: "catalog/getUiFlags",
      categories:"catalog/getCategories",
      catelogueItems:"catalog/getCatalogItems",
    }),
    merchant(){
      const newMerchant = this.$store.getters["merchants/getCurrentMerchant"](this.$route.params.accountId);
      return newMerchant;
    },
    flattenedDisplayCatalogItems(){
      const result =[];

      this.displayCatalogItems.forEach(catalog=>{
        // const stokedItems = this.filterStockedItems(catalog.catalogItems);
        catalog.catalogItems.forEach((catelogueItem,idx)=>{
          result.push({
            ...catelogueItem,
            categoryId: catalog.categoryId,
            isFirstItemOfCatagory: idx === 0,
            isLastItemOfCatagory: idx === (catalog.catalogItems.length -1),
          })
        });
      });
      // remove the categories which don't have items
      return result;
    },
    isActiveCategory() {
      return category => {
        return category.id === this.selectedCategoryId;
      };
    },
  },
  mounted(){
    this.loadInitialCategories();
    window.addEventListener("scroll", this.handleWindowScroll);
  },
  watch:{
    categories(){
      this.loadInitialCategories();
    },
    allCatalogItems(){
      this.$nextTick(()=>{
        this.initCatagoryObserver(); // no need to observer last item since we are fetching all items initially
      })
    },
    '$route.query.catagory': {
      handler(newCategory, oldCategory) {
        if (oldCategory && !newCategory) {
          this.loadInitialCategories();
        }
      },
      immediate: true,
    },
    // watch end
  },
  beforeDestroy() {
    this.cleanupObserver();
    window.removeEventListener("scroll", this.handleWindowScroll);
  },
  methods:{
    goToProduct(categoryId) {
      this.$router.push(`/app/accounts/${this.$route.params.accountId}/product?product_id=${categoryId}`);
    },
    async onCategorySingleSelect(catagoryIndex){
      this.isCtgClickByUser = true;
      this.isCatagoryClick = true;
      await this.$nextTick();

      this.selectedCategoryId = this.allCatalogItems[catagoryIndex].id;
      this.scrollToContainerTop(true);
      await this.$nextTick()
      // clear display list and set only the selected ctg list
      this.displayCatalogItems = [this.allCatalogItems[catagoryIndex]];

      this.$router.replace({
        // query: { ...this.$route.query, catagory: categoryTitle }
        query: { ...this.$route.query, catagory: this.allCatalogItems[catagoryIndex].category }
      });

      setTimeout(()=>{
        this.isCatagoryClick = false; // make false after self scroll complete
      },500)
    },


    async onCategoryClick(categoryId,categoryTitle) {
      this.selectedCategoryId = categoryId;
      // only show this category items

      //const catagoryTitle = this.$route.query.catagory?.toLowerCase();
      if (categoryTitle && categoryTitle?.toLowerCase() !== this.$route.query.catagory?.toLowerCase()) {
        this.$router.replace({
          query: { ...this.$route.query, catagory: categoryTitle }
        });
      }

      const catalogSection = document.getElementById(`catalog_container-${categoryId}`);
      if (catalogSection) {
        catalogSection.scrollIntoView({behavior:"smooth",})
      }
    },

    async loadInitialCategories(){
      this.isCatelogueItemsFetching= true;
      const formattedCatelogItems = this.categories.map(item=>({
        id:item.id,
        categoryId:item.id,
        category: item.title,
        catalogItems:[],  // {"id": 3,"title": "x","availability": "in stock","available_inventory": 0, variants:[recurse item]}
      }));
      const catagoryTitle = this.$route.query.catagory?.toLowerCase();

      // load the first list at initial
      for(let i=0;i<formattedCatelogItems.length;i++){
        const catalogItemsRes = await this.$store.dispatch("catalog/getCatalogItemsByCategoryId",{category_id:formattedCatelogItems[i].categoryId});
        if (catalogItemsRes.length > 0) {
          formattedCatelogItems[i].catalogItems = catalogItemsRes;
        }
      }

      // filter the list based on in-stock, available_inventory > 0
      const instockedCatalogs = formattedCatelogItems.map(category=>{
        const CatalogItemsWithVariant = category.catalogItems.flatMap(catalogItem =>[
          {
            // Main product
            id: catalogItem.id,
            title: catalogItem.title,
            availability: catalogItem.availability,
            available_inventory: catalogItem.available_inventory,
          },
          ...(catalogItem.variants ?? []),
        ])

        // filter by condition, instock and available_inventory > 0
        const validCatalogItems = CatalogItemsWithVariant.filter(item=> item.available_inventory > 0 && item.availability === "in stock")
        return {
          ...category,
          validCatalogItems, // only to check length, don't show in UI, show only the main product(not variant)
        }
      })
      .filter(category => category.validCatalogItems.length > 0);
      const instockedCategories = this.categories.filter(category=>instockedCatalogs.some(inCtg=>inCtg.categoryId === category.id));

      this.instockedCategories = instockedCategories;
      this.allCatalogItems = instockedCatalogs;
      this.displayCatalogItems = instockedCatalogs;
      for(let i=0;i<instockedCatalogs.length;i++){
        this.selectedCategoryId = instockedCatalogs[i].categoryId;
        if (catagoryTitle) {
          this.isCtgClickByUser = true;
          this.isCatagoryClick = true;
          if ( instockedCatalogs[i].category?.toLowerCase() === catagoryTitle) {
            // only set selected list to display
            this.displayCatalogItems = [instockedCatalogs[i]];
            // Wait for the DOM to render the new catalog items
            await this.$nextTick();
            this.$nextTick(() => {
              // this.selectedCategoryId = formattedCatelogItems[i].categoryId; // This will execute after the DOM is updated
              this.onCategoryClick(instockedCatalogs[i].categoryId);
              setTimeout(()=>{
                this.isCatagoryClick = false;
              },500)
            });
            break;
          }
        }else{
          break;
        }
      }
      this.isCatelogueItemsFetching = false;
      await this.$nextTick();
      this.isDataLoaded = true;
    },
   filterStockedItems(catalogItems){
    return catalogItems.filter(el=>el.availability === 'in stock')
   },


   initCatagoryObserver() {
      // Options for the Intersection Observer
      const options = {
        root: null, // Observing relative to the viewport
        rootMargin: '0px', // No margin
        threshold: 0.1, // Trigger when at least 10% of the section is visible
      };
      this.observer = new IntersectionObserver(this.handleIntersection, options);
      this.$refs.catalogSections?.forEach(section => {
        this.observer.observe(section);
      });
    },
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const categoryId = entry.target.getAttribute('data-category-id');
          // only if that catagory have length
          const catalogIdx = this.allCatalogItems.findIndex(el=>el.categoryId === parseInt(categoryId));
          if (this.allCatalogItems[catalogIdx].catalogItems.length) {
            this.selectedCategoryId = parseInt(categoryId);
          }
        }
      });
    },
    cleanupObserver() {
      // Disconnect the observer to prevent memory leaks
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    getCatalogRef(catalog) {
      return catalog.isFirstItemOfCatagory ? 'catalogSections' : null;
    },
    handleWindowScroll(){
      if (this.isCatagoryClick) {
        return
      }
      if (!this.isDataLoaded) {
        return
      }

      const containerRect = this.$refs.catalogDisplayContainer.getBoundingClientRect();
      const topRect = this.$refs.topSkeleton.getBoundingClientRect();
      const bottomRect = this.$refs.bottomSkeleton.getBoundingClientRect();

      const targetPosition = this.scrollToContainerTop(false) - 50;
      // if (topRect.top >= containerRect.top && window.scrollY <= 0) {
      if (topRect.top >= containerRect.top && window.scrollY <= targetPosition) {
        // console.log("Cannot go more top",targetPosition);
        const currentIdx = this.allCatalogItems.findIndex(el=>el.id === this.selectedCategoryId);
        if (currentIdx > 0) {
          // do check if user click else onCategoryClick()
          if (this.isCtgClickByUser) {
            this.onCategorySingleSelect(currentIdx-1)
          }
          // else{
          //   this.onCategoryClick(this.allCatalogItems[currentIdx - 1].categoryId,currentCategory.category)
          // }
        }
      }
      if (bottomRect.bottom <= window.innerHeight && window.scrollY + window.innerHeight >= document.body.scrollHeight) {
        // console.log("Cannot go more bottom");
        const currentIdx = this.allCatalogItems.findIndex(el=>el.id === this.selectedCategoryId);
        if (currentIdx < this.allCatalogItems.length -1) {
          if (this.isCtgClickByUser) {
            this.onCategorySingleSelect(currentIdx+1)
          }
          // else{
          //   this.onCategoryClick(this.allCatalogItems[currentIdx + 1].categoryId,currentCategory.category)
          // }
        }
      }
    },
    scrollToContainerTop(isScroll= false) {
      const catalogDisplayContainer = document.getElementById('catalogDisplayContainer');

      if (catalogDisplayContainer) {
        const navbarHeight = 45;
        const infoHeight = document.querySelector('.info')?.offsetHeight || 0;
        const categoriesHeight = document.getElementById('item_categories')?.offsetHeight || 0;

        const targetPosition = infoHeight + categoriesHeight + navbarHeight;
        if (isScroll) {
          window.scrollTo({
              top: targetPosition,
              behavior: "smooth",
          });
        }else{
          return targetPosition
        }
      }
    },

  },
}
</script>

<style lang="scss" scoped>
  .info{
    margin: 25px 5%;
    max-width: 400px;
    @media (min-width: 412px) {margin: 25px auto;}

    .description{
      background-color: var(--bg-secondary);
      text-transform: capitalize;
    }
  }
  .logo_wrapper{
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    text-align: center;
    img{
      width: 140px;
      height: 140px;
    }

  }
  .current_queue{
    display: flex;
    align-items: flex-end;
    // justify-content: center;
    text-align: center;
    margin-top: var(--space-two);
    /*
    h3{
      color: var(--txt-secondary);
      margin: 0;
      line-height: normal;
    }
    */
    p{
      margin: 0;
    }
  }
  .item_categories {
    position: sticky;
    top: 51px;
    background-color: var(--bg-secondary);
    display: flex;
    padding: 10px 0;
    flex-wrap: wrap;
    justify-content: center;
    // font-size: var(--fs-lg);

    button{
      font-weight: 600;
      color: var(--txt-secondary);
      padding: 0 8px;
      border-right: 1px solid  var(--txt-secondary);
      cursor: pointer;

      &:last-child{
        border-right: none;
      }
    }
    .active{
      color: var(--primary-color);
    }
  }
  #catalogDisplayContainer{
    min-height: 100vh;
  }
  .catalog_container{
    // margin-top: 50px;
  }
  .categories{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    list-style: none;
  }
  .category_title{
    text-align: center;
    margin: 50px auto;
    background-color: var(--bg-secondary);
  }
  .category_card{
    max-width: 200px;
    min-width: 150px;
    flex: 1 0 0;
    padding: var(--p-md);
    background: var(--bg-secondary);
    border-radius: var(--b-md);
    cursor: pointer;

    .card_img{
      object-fit: cover;
      height: 150px;
      width: 100%;
    }

    .title{
      font-size: 1.8rem;
    }

    .footer{
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon{
        color: var(--primary-color);
      }
    }
  }
</style>
